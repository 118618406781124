import React, { useMemo, useState } from "react";
import { Container } from "src/components";
import * as config from "../AboutUs/config";
import { FaArrowRightLong } from "react-icons/fa6";

const MileStone = () => {
  const [selectedCircle, setSelectedCircle] = useState<number>(0);

  const handleCircleClick = (index: number) => {
    setSelectedCircle(index);
  };

  const onArrowClick = (action: "inc" | "dec") => {
    if (action === "inc" && !(selectedCircle > 3)) {
      setSelectedCircle((prevVal) => prevVal + 1);
    }
    if (action === "dec" && !(selectedCircle < 1)) {
      setSelectedCircle((prevVal) => prevVal - 1);
    }
  };

  const yearData = useMemo(() => {
    return config.milestones[selectedCircle as keyof typeof config.milestones];
  }, [selectedCircle]);
  return (
    <Container className="min-h-screen">
      <div className="text-[#00379E] text-[32px] md:text-[60px] font-semibold my-0 text-center">
        Our Milestones Journey
      </div>
      <div className="flex items-center justify-evenly relative mt-28 md:mt-40 px-6 md:px-0">
        <div
          className={`rounded-full w-8 md:w-14 h-8 md:h-14 absolute left-0 z-[9999] flex items-center justify-center  ${
            selectedCircle < 1
              ? "bg-[#D6E3F8] cursor-not-allowed"
              : "bg-blue-100 cursor-pointer"
          }`}
          onClick={() => onArrowClick("dec")}
        >
          <FaArrowRightLong className="rotate-180 text-basic-white w-[18px] md:w-[36px] h-[18px] md:h-[36px]" />
        </div>
        <div className="absolute w-full h-[5px] bg-[#D9D9D9] z-0"></div>
        {Array.from({ length: 5 }).map((_, index) => (
          <div className="z-[9999]">
            <div
              className={`absolute top-[-40px] md:top-[-60px] cursor-pointer text-md md:text-2xl translate-x-[-30%] ${
                selectedCircle === index
                  ? "font-bold text-lg md:text-4xl -translate-y-1 md:-translate-y-2 "
                  : "hover:scale-150 transition-all"
              }`}
              onClick={() => handleCircleClick(index)}
            >
              {config.milestones[index as keyof typeof config.milestones].year}
            </div>
            <div
              key={index}
              className={`w-4 md:w-6 h-4 md:h-6 rounded-full    ${
                selectedCircle === index
                  ? "bg-[#1DE9B6]"
                  : "bg-basic-white border-2 border-[#D9D9D9]"
              }`}
            />
          </div>
        ))}
        <div
          className={`rounded-full w-8 md:w-14 h-8 md:h-14 bg-blue-100 absolute right-0 flex items-center justify-center   ${
            selectedCircle > 3
              ? "bg-[#D6E3F8] cursor-not-allowed"
              : "bg-blue-100 cursor-pointer"
          }`}
          onClick={() => onArrowClick("inc")}
        >
          <FaArrowRightLong className="text-basic-white w-[18px] md:w-[36px] h-[18px] md:h-[36px]" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-4 md:mt-10">
        <div className="relative flex items-center justify-center h-32 w-[50%]">
          <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-0.5 bg-[#80868A]"></div>
          <p className="text-[#1DE9B6] text-[80px] md:text-[100px] font-bold relative text-center bg-basic-white w-max z-10 px-12">
            {yearData.year}
          </p>
        </div>
        <img
          className={
            yearData.year === "2023"
              ? "h-26 p-8"
              : yearData.year === "2020"
              ? "h-32"
              : yearData.year === "2022"
              ? "h-32 pt-3"
              : "h-22"
          }
          alt={yearData.year}
          src={yearData.image}
        />
        <div className="text-[#565A5C] text-lg md:text-xl">
          {yearData.heading}
        </div>
      </div>
    </Container>
  );
};

export default MileStone;
