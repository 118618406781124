import { useNavigate } from "react-router-dom";

type BlogPreviewProps = {
  img: string;
  heading: string;
  tags: string[];
  date: string;
  shortDesc: string;
  isLast: boolean;
  _id: string;
};

const BlogPreview = ({
  img,
  heading,
  date,
  shortDesc,
  tags,
  isLast,
  _id,
}: BlogPreviewProps) => {
  const navigate = useNavigate();

  const onBlogClick = () => {
    navigate(`/blogs/${_id}`);
  };

  return (
    <div
      className={`grid grid-cols-3 gap-x-8 py-4 md:py-8 cursor-pointer hover:scale-[102%] transition-all ${
        isLast ? "" : "border-b border-grey-50"
      }`}
      onClick={onBlogClick}
    >
      <div className="col-span-3 md:col-span-1">
        <img src={img} alt={heading} className="h-[200px] md:h-[250px]" />
      </div>
      <div className="col-span-3 md:col-span-2 space-y-4 mt-4 md:mt-0">
        <div className="text-xl md:text-2xl font-semibold">{heading}</div>
        <div className="text-lg md:text-xl mt-4">{shortDesc}</div>
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {tags.map((tag) => {
              return (
                <div className="bg-[#C4C4C44A] text-[#575757B0] px-2 md:px-4 py-1 md:py-2 rounded-sm text-sm md:text-md">
                  {tag}
                </div>
              );
            })}
          </div>
          <div className="text-[#575757B5] text-sm md:text-2xl">{date}</div>
        </div>
      </div>
    </div>
  );
};

export default BlogPreview;
