import { Container, Navbar } from "src/components";
import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import TeamCard from "./TeamCard";
import { teamsData } from "./teams.data";

const OurTeam = () => {
  return (
    <div>
      <div className="bg-gradient-to-r from-[#3882E0] from-22.47% to-[#003CA8] to-71.92% relative min-h-[95vh]">
        <Container>
          <Navbar />
          <div className="grid grid-cols-1 md:grid-cols-2 mt-12 md:mt-32">
            <div className="space-y-5 md:space-y-10">
              <div className="text-[30px] md:text-[52px] text-basic-white">
                Empowering Trade,
                <br /> Maximising Growth.
              </div>
            </div>
          </div>
        </Container>
        <div className="w-full h-auto md:h-[220px] absolute bottom-0 z-0">
          <img
            src={FAQBg}
            alt="Full Screen"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <Container>
        <div>
          <div className="flex justify-center">
            <p className="text-4xl md:text-6xl font-semibold my-4 md:my-20">
              Our Team
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
            {teamsData.map((user) => {
              return <TeamCard {...user} />;
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OurTeam;

export type TeamCardType = {
  linkedInlink?: string;
  img: string;
  name: string;
  designation: string;
  description: string;
};
