import One from "src/assets/icons/solutions/treatment.png";
import Two from "src/assets/icons/solutions/benefit.png";
import Three from "src/assets/icons/solutions/cash-flow.png";
import Four from "src/assets/icons/solutions/accounting.png";
import Five from "src/assets/icons/solutions/save-money (1).png";
import Six from "src/assets/icons/solutions/cash-machine.png";
import Seven from "src/assets/icons/solutions/loan.png";
import Eight from "src/assets/icons/solutions/increase (3).png";
import Nine from "src/assets/icons/solutions/crowdfunding.png";
import Ten from "src/assets/icons/solutions/dollar.png";
import Eleven from "src/assets/icons/solutions/money (3).png";
import Twelve from "src/assets/icons/solutions/icons8-duration-finance-90.png";
import Manufacturer from "src/assets/gifs/manufacturer.gif";
import DistributorProgram from "src/assets/gifs/distributor-program.gif";
import PidPayments from "src/assets/gifs/pid-payments.gif";
import VendorPayments from "src/assets/gifs/vendor-payment.gif";

export const solutionsPage = {
  "manufacturer-supplier": {
    main: {
      heading: "Manufacturer- Dealer Sales Invoice Discounting",
      subHeading:
        "If you operate a manufacturing business, you can realize your receivables and get instant payments on behalf of your Dealers and B2B Buyers- That too without assuming any credit risk!",
    },
    working: {
      heading: "How Manufacturer- Dealer Sales Invoice Program works?",
      gif: Manufacturer,
    },
    winWIn: {
      heading: "Win-Win for both Manufacturer and Supplier",
      leftCard: {
        heading: "Benefits to Manufacturer",
        cards: [
          {
            image: One,
            heading: "Working Capital to reduce outstanding & Risk",
            subHeading: "Increase sales and loyalty without addtional Risks",
          },
          {
            image: Two,
            heading: "Incentives to Retailers for faster cash rotation",
            subHeading: "Offer incentives to retailers for early part payments",
          },
          {
            image: Three,
            heading: "Faster Cash Conversion Cycle",
            subHeading: "Shift from credit to cash without impacting sales",
          },
          {
            image: Four,
            heading: "Off-Balance Sheet Funds",
            subHeading: "Reduced Debt and improve balance sheet health",
          },
        ],
      },
      rightCard: {
        heading: "Benefits to Supplier",
        cards: [
          {
            image: Five,
            heading: "Earning CD Benefits",
            subHeading:
              "Eligible to earn CD benefits for even partial payments",
          },
          {
            image: Six,
            heading: "Additional credit line",
            subHeading:
              "Working Capital without collateral and opportunity to earn CD",
          },
          {
            image: Seven,
            heading: "Upfront payment to Distributor",
            subHeading:
              "Pemant initiates upfront payments to distributors for retailers, building trust and credibility",
          },
          {
            image: Eight,
            heading: "Increase in Sales",
            subHeading:
              "With a faster repayment cycle, retailers can increase the business’ rotations - increasing sale",
          },
        ],
      },
    },
  },
  "distributor-retailer": {
    main: {
      heading: "Distributor - Retailer Sales Invoice",
      subHeading:
        "If you are an Authorized Brand Distributor or Stockist, you can realize your receivables and get instant payments on behalf of your B2B Retailers - That too without assuming any credit risk!",
    },
    working: {
      heading: "How Distributor -Retailer Sales Invoice Program works?",
      gif: DistributorProgram,
    },
    winWIn: {
      heading: "Win-Win for both Distributor and Retailers",
      leftCard: {
        heading: "Benefits to Distributors",
        cards: [
          {
            image: One,
            heading: "Working Capital to reduce outstanding & Risk",
            subHeading: "Increase sales and loyalty without addtional Risks",
          },
          {
            image: Two,
            heading: "Incentives to Retailers for faster cash rotation",
            subHeading: "Offer incentives to retailers for early part payments",
          },
          {
            image: Three,
            heading: "Faster Cash Conversion Cycle",
            subHeading: "Shift from credit to cash without impacting sales",
          },
          {
            image: Four,
            heading: "Off-Balance Sheet Funds",
            subHeading: "Reduced Debt and improve balance sheet health",
          },
        ],
      },
      rightCard: {
        heading: "Benefits to Retailers",
        cards: [
          {
            image: Five,
            heading: "Earning CD Benefits",
            subHeading:
              "Eligible to earn CD benefits for even partial payments",
          },
          {
            image: Six,
            heading: "Additional credit line",
            subHeading:
              "Working Capital without collateral and opportunity to earn CD",
          },
          {
            image: Seven,
            heading: "Upfront payment to Distributor",
            subHeading:
              "Pemant initiates upfront payments to distributors for retailers, building trust and credibility",
          },
          {
            image: Eight,
            heading: "Increase in Sales",
            subHeading:
              "With a faster repayment cycle, retailers can increase the business’ rotations - increasing sale",
          },
        ],
      },
    },
  },
  "vendor-financing": {
    main: {
      heading: "Vendor Financing",
      subHeading: "Offer flexible early payment options to your MSME Vendors",
    },
    working: {
      heading: "How Vendor Financing Program works?",
      gif: VendorPayments,
    },
    winWIn: {
      heading: "Win-Win for both Corporate Anchor and Vendor",
      leftCard: {
        heading: "Benefits to Corporate Anchor",
        cards: [
          {
            image: Nine,
            heading: "Working Capital to reduce outstanding & Risk",
            subHeading: "Collateral free working capital",
          },
          {
            image: Ten,
            heading: "Incentives to Retailers for faster cash rotation",
            subHeading: "Improved cash flows for business survival",
          },
          {
            image: Eleven,
            heading: "Faster Cash Conversion Cycle",
            subHeading: "Stability in supply chain through timely payments",
          },
          {
            image: Twelve,
            heading: "Off-Balance Sheet Funds",
            subHeading:
              "Lower borrowing costs, higher profits with anchor finance.",
          },
        ],
      },
      rightCard: {
        heading: "Benefits to Vendor",
        cards: [
          {
            image: Five,
            heading: "Earning CD Benefits",
            subHeading:
              "Off- Balance sheet – Doesn’t impact the debt profile or CIBIL Score",
          },
          {
            image: Six,
            heading: "Additional credit line",
            subHeading: "Discounting Fees",
          },
          {
            image: Seven,
            heading: "Upfront payment to Distributor",
            subHeading: "Grow business and income without taking a loan",
          },
          {
            image: Eight,
            heading: "Increase in Sales",
            subHeading: "Flexible liquidity options",
          },
        ],
      },
    },
  },
  "purchase-invoice-discounting": {
    main: {
      heading: "Purchase Invoice Discounting",
      subHeading:
        "Avail flexible liquidity for making supplier specific payments",
    },
    working: {
      heading: "How Purchase Invoicing Program works?",
      gif: PidPayments,
    },
    winWIn: {
      heading: "Win-Win for both MSME Buyer and Corporate Supplier",
      leftCard: {
        heading: "Benefits to MSME Buyer",
        cards: [
          {
            image: One,
            heading: "Working Capital to reduce outstanding & Risk",
            subHeading: "Collateral free working capital for all the vendors",
          },
          {
            image: Two,
            heading: "Incentives to Retailers for faster cash rotation",
            subHeading: "Low interest rates for the buyer",
          },
          {
            image: Three,
            heading: "Faster Cash Conversion Cycle",
            subHeading: "Flexible liquidity options",
          },
          {
            image: Four,
            heading: "Off-Balance Sheet Funds",
            subHeading:
              "Avail CD by making upfront payments to large suppliers",
          },
        ],
      },
      rightCard: {
        heading: "",
        cards: [],
      },
    },
  },
};
