import { useNavigate } from "react-router-dom";
import { Container, Navbar } from "src/components";
import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import Blog1 from "src/assets/images/blogs/blog-1.svg";
import { blogs } from "./blogs.data";
import BlogPreview from "./Blog.preview";

const Blogs = () => {
  const navigate = useNavigate();

  const onLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#3882E0] from-22.47% to-[#003CA8] to-71.92% relative min-h-[95vh]">
        <Container>
          <Navbar />
          <div className="grid grid-cols-1 md:grid-cols-4 mt-12 md:mt-32 md:gap-x-8 gap-y-4 md:gap-y-0 items-center">
            <div className="col-span-1 self-start mt-4">
              <img src={Blog1} alt="Blog1" className="w-full" />
            </div>
            <div className="col-span-3 text-basic-white">
              <div className="text-2xl md:text-5xl">{blogs[0].heading}</div>
              <div className="text-lg md:text-2xl">{blogs[0].shortDesc}</div>
              <div className="flex-col md:flex mt-8 md:space-x-12 space-y-4 md:space-y-0 items-center">
                <div
                  className="bg-basic-white text-blue-50 rounded-md px-4 py-1 text-xl md:text-2xl cursor-pointer text-center md:text-left z-20"
                  onClick={() => onLinkClick("/blogs/what_is_loc")}
                >
                  Read latest article
                </div>
                <div className="text-xl md:text-2xl">{blogs[0].date}</div>
              </div>
            </div>
          </div>
        </Container>
        <div className="w-full h-auto md:h-[220px] absolute bottom-0 z-0">
          <img
            src={FAQBg}
            alt="Full Screen"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <Container>
        <div className="grid grid-cols-5">
          <div className="col-span-5 md:col-span-4">
            {blogs.slice(1).map((blog, index) => {
              return (
                <BlogPreview
                  {...blog}
                  key={blog._id}
                  isLast={blogs.length - 2 === index}
                />
              );
            })}
          </div>
          <div className="hidden md:block col-span-1"></div>
        </div>
      </Container>
    </>
  );
};

export default Blogs;
