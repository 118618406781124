import React from 'react';
import verificationIcon from '../../assets/images/verification-pages/tick-icon.svg';

const VerifiedBuyer: React.FC = () => {
  return (
    <div className="flex flex-col items-center p-4 ">
      <img src={verificationIcon} className="w-16 h-16 mb-4" alt="Verification Icon" />
      <p className="text-center text-lg font-bold mb-2">Verified</p>
      <p className="text-center text-base mb-2">
        To complete your Application Journey <br /> Please Visit:
      </p>
      <p className="text-center mb-4">
        <a href="https://play.google.com/store/apps/details?id=com.pemantapp" className="text-blue-50 underline font-semibold">
          Pemant App Link
        </a>
      </p>
    </div>
  );
};

export default VerifiedBuyer;
