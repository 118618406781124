import { Container } from "src/components";
import SellerManufacturer from "src/assets/images/home/icons8-distributor-100.png";
import SellerDistributer from "src/assets/images/home/invoice.png";
import BuyerVendor from "src/assets/images/home/icons8-manufacturer-64.png";
import BuyerPurchase from "src/assets/images/home/icons8-cheque-100.png";
import { Link } from "react-router-dom";

type CarsProps = {
  children: React.ReactNode;
};

type WidgetProps = {
  color: "blue" | "green";
  img: string;
  heading: string;
  redirectUrl: string;
};

const Card = ({ children }: CarsProps) => {
  return (
    <div className="bg-basic-white rounded-2xl p-4 md:p-8 py-4 w-full shadow-lg ">
      {children}
    </div>
  );
};

const Widget = ({ color, img, heading, redirectUrl }: WidgetProps) => {
  return (
    <div className="border border-grey-50 shadow-md rounded-md rounded-t-none relative">
      <div
        className={`h-3 w-full ${
          color === "blue" ? "bg-blue-50" : "bg-green-100"
        }`}
      />
      <div className="p-3 flex justify-between items-center flex-col mt-4 md:mt-8">
        <img alt={heading} src={img} className="h-16" />
        <div className="text-sm font-bold text-center mb-20 md:mb-28 mt-2">
          {heading}
        </div>

        <Link
          className={`${
            color === "blue" ? "bg-blue-50" : "bg-green-50"
          } rounded-md text-center text-basic-white w-[90%] md:w-10/12 max-w-[300px] mt-16 md:mt-20 py-3 absolute bottom-4`}
          to={redirectUrl}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
};

const RightSolution = () => {
  return (
    <div className="bg-[#EBF3FDC9]">
      <Container className="md:pb-40">
        <div className="text-3xl md:text-6xl py-0 md:py-6 font-semibold text-blue-50 mb-2 md:mb-6">
          Choose the solution that’s right for your business.
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 lg:gap-14">
          <Card>
            <div className="text-center text-2xl md:text-3xl text-blue-50 mb-4 md:mb-10 font-semibold">
              For Sellers
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <Widget
                color="blue"
                img={SellerManufacturer}
                heading="Manufacturer-Dealer Sales Invoice Discounting"
                redirectUrl="/solutions/manufacturer-supplier"
              />
              <Widget
                color="blue"
                img={SellerDistributer}
                heading="Distributor– Retailer Sales Invoice Discounting"
                redirectUrl="/solutions/distributor-retailer"
              />
            </div>
          </Card>
          <Card>
            <div className="text-center text-2xl md:text-3xl text-blue-50 mb-4 md:mb-10 font-semibold">
              For Buyers
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <Widget
                color="green"
                img={BuyerVendor}
                heading="Vendor Financing"
                redirectUrl="/solutions/vendor-financing"
              />
              <Widget
                color="green"
                img={BuyerPurchase}
                heading="Purchase Invoice Discounting"
                redirectUrl="/solutions/purchase-invoice-discounting"
              />
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default RightSolution;
