import React from "react";
import LogoHeader from "src/assets/images/home/pemant.svg";
import LogoBlack from "src/assets/icons/logo-black.svg";
import GetStarted from "src/assets/icons/get-started.svg";
import Phone from "src/assets/icons/phone.svg";
import Mail from "src/assets/icons/mail.svg";
import "./styles.css";
import { Menu, MenuItem } from "src/components";
import * as config from "./config";
import { useNavigate } from "react-router-dom";
import { NavbarProps } from ".";

const DesktopNavbar = ({ theme }: NavbarProps) => {
  const navigate = useNavigate();

  const onLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-3 md:col-span-2 flex items-center">
        <img
          src={theme === "dark" ? LogoBlack : LogoHeader}
          className="w-[100px] md:w-[120px] xl:w-[150px] cursor-pointer"
          alt="Pemant.in"
          onClick={() => onLinkClick("/")}
        />
      </div>
      <div className="flex col-span-7  space-x-[5%]  items-center">
        <Menu
          label="Our Solutions"
          className={`underline-animation ${
            theme === "dark" ? "text-basic-black" : "text-basic-white"
          } text-2xl xl:text-3xl font-semibold`}
        >
          {config.ourProducts.map((el, index) => {
            return (
              <MenuItem
                key={`${el.heading} - ${index}`}
                isBg={false}
                onClick={() => onLinkClick(`/solutions/${el.link}`)}
              >
                <div className="grid grid-cols-6 p-2">
                  <div className="col-span-1">
                    <img src={el.image} alt={el.heading} />
                  </div>
                  <div className="col-span-5 text-sm hover:translate-x-3 hover:bg-blue-100/10 px-3 py-1 rounded-sm ease-linear transition-all hover:text-blue-50">
                    <div className="font-semibold">{el.heading}</div>
                    <div className="">{el.subheading}</div>
                  </div>
                </div>
              </MenuItem>
            );
          })}
        </Menu>
        <Menu
          label="Company"
          className={`underline-animation ${
            theme === "dark" ? "text-basic-black" : "text-basic-white"
          } text-2xl xl:text-3xl font-semibold`}
        >
          <MenuItem onClick={() => onLinkClick("/about-us")}>About Us</MenuItem>
          <MenuItem onClick={() => onLinkClick("/our-team")}>Our Team</MenuItem>
        </Menu>
        <Menu
          label="Resources"
          className={`underline-animation ${
            theme === "dark" ? "text-basic-black" : "text-basic-white"
          } text-2xl xl:text-3xl font-semibold`}
        >
          <MenuItem onClick={() => onLinkClick("/blogs")}>Blog</MenuItem>
        </Menu>
      </div>
      <div className="col-span-2 flex space-x-[5%] md:space-x-[10%] items-center">
        <Menu
          label="Contact"
          className={`underline-animation ${
            theme === "dark" ? "text-basic-black" : "text-basic-white"
          } text-2xl xl:text-3xl font-semibold`}
        >
          <MenuItem className="flex space-x-4 items-center">
            <img src={Mail} alt="Mail" className="mt-1" />
            <a
              href="mailto:support@credochain.com"
              className="hover:text-blue-50 hover:underline"
            >
              support@credochain.com
            </a>
          </MenuItem>
          <MenuItem className="flex space-x-4">
            <img src={Phone} alt="Phone" className="mt-1" />
            <a
              href="tel:+91 11-41716192"
              className="hover:text-blue-50 hover:underline"
            >
              +91 11-41716192
            </a>
          </MenuItem>
          <MenuItem
            className="flex space-x-4 z-20"
            onClick={() => onLinkClick("/solution-type")}
          >
            <img src={GetStarted} alt="Get Started" className="mt-0.5" />
            <div>Get Started</div>
          </MenuItem>
        </Menu>

        <Menu
          label="Login"
          className={`${
            theme === "dark"
              ? "bg-blue-50 text-basic-white"
              : "bg-basic-white text-blue-50 font-semibold"
          } px-7 py-1.5 rounded-lg cursor-pointer text-lg md:text-xl`}
        >
          <a href="https://seller.pemant.in/" target="_blank" rel="noreferrer">
            <MenuItem>
              <div>Supplier Dashboard</div>
              <div className="text-xs text-blue-100 hover:text-blue-100 ml-3 hover:underline">seller.pemant.in</div>
            </MenuItem>
          </a>
        </Menu>
      </div>
    </div>
  );
};

export default DesktopNavbar;
