import React from "react";
import CustomizableScf from "./CustomizableScf";
import AutomatedInvoice from "./AutomatedInvoice";
import EffortlessLending from "./EffortlessLending";
import LendingPartner from "./LendingPartner";

const FinancialInstitutions = () => {
  return (
    <div>
      <CustomizableScf />
      <AutomatedInvoice />
      <EffortlessLending />
      <LendingPartner />
    </div>
  );
};

export default FinancialInstitutions;
