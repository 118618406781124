import React from "react";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?:"submit" | "reset" | "button" | undefined;
};

const Button = ({ children, onClick, type=undefined,className = "" }: ButtonProps) => {
  return (
    <button
      className={`cursor-pointer text-basic-white bg-blue-50 w-full md:w-[150px] xl:w-[200px] rounded-md p-3 text-center font-semibold ${className}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
