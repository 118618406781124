import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Navbar } from "src/components";
import { blogs } from "./blogs.data";
import RecentPost from "./RecentPost";

export type Blog = {
  _id: string;
  heading: string;
  shortDesc: string;
  tags: string[];
  date: string;
  fullBlog: string;
  img: string;
};

const BlogsLayout = () => {
  const { blogId } = useParams<{ blogId: string }>();
  const [blog, setBlog] = useState<Blog>();

  useEffect(() => {
    const blogToShow = blogs.find((blog) => blog._id === blogId);
    if (blogToShow) {
      setBlog(blogToShow);
    }
  }, [blogId]);

  function createMarkup() {
    return blog?.fullBlog ? { __html: blog?.fullBlog } : { __html: "" };
  }

  const recentBlogs = useMemo(() => {
    return blogs.filter((blog) => blog._id !== blogId);
  }, [blogId]);

  return (
    <Container className="">
      <Navbar theme="dark" />
      <div className="grid grid-cols-4 mt-12 md:mt-8 md:gap-x-12 gap-y-2 md:gap-y-0">
        <div className="col-span-4 md:col-span-3">
          <div className="w-full md:h-[600px] ">
            <img
              src={blog?.img}
              alt={blog?.shortDesc}
              className="w-full h-full md:object-cover"
            />
          </div>
          {blog?.fullBlog && <p dangerouslySetInnerHTML={createMarkup()} />}
        </div>
        <div className="col-span-4 md:col-span-1">
          <RecentPost blogs={recentBlogs} />
        </div>
      </div>
    </Container>
  );
};

export default BlogsLayout;
