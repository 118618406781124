import { Accordion, Container } from "src/components";
import * as config from "../config";
import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import { ReactNode } from 'react';

interface FAQ {
  ques: string;
  ans: ReactNode;
}

const FAQ = () => {
  return (
    <div className="bg-gradient-to-r from-[#1556BD] from-28.32% to-[#3882E0] to-115.84% ">
      <Container>
        <div className="text-3xl md:text-5xl text-center py-3 md:py-6 font-semibold text-basic-white">
          Frequently Asked Questions
        </div>
        <div className="space-y-5">
          {config.FAQs.map((faq:FAQ) => (
            <Accordion summary={faq.ques} details={faq.ans} />
          ))}
        </div>
      </Container>

      <div className="w-full h-auto md:h-[220px]">
        <img
          src={FAQBg}
          alt="Full Screen"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default FAQ;
