import { useState } from "react";
import SellerManufacturer from "src/assets/images/home/icons8-distributor-100.png";
import SellerDistributer from "src/assets/images/home/invoice.png";
import BuyerVendor from "src/assets/images/home/icons8-manufacturer-64.png";
import BuyerPurchase from "src/assets/images/home/icons8-cheque-100.png";
import LogoHeader from "src/assets/images/home/pemant.svg";
import { Button } from "src/components";
import { useNavigate } from "react-router-dom";

type TabType = "buyer" | "seller";

type CardProps = {
  img: string;
  text: string;
  onClick: (text: string) => void;
  value?: string;
};

const Card = ({ img, text, onClick, value = "" }: CardProps) => {
  return (
    <div
      className={`border border-grey-50 rounded-xl grid grid-cols-6 w-full p-5 cursor-pointer ${
        value === text ? "!border-blue-50 text-blue-50 ring-blue-50" : ""
      }`}
      onClick={() => onClick(text)}
    >
      <div className="col-span-2">
        <img className="h-16" src={img} alt={text} />
      </div>
      <div className="col-span-4 flex items-center">
        <div className="text-xl md:text-2xl font-semibold">{text}</div>
      </div>
    </div>
  );
};
const SolutionType = () => {
  const [activeTab, setActiveTab] = useState<TabType>("buyer");
  const [selectedCard, setSelectedCard] = useState<undefined | string>(
    undefined
  );

  const navigate = useNavigate();
  const onTabClick = (tab: TabType) => {
    setActiveTab(tab);
    setSelectedCard(undefined)
  };

  const onCardClick = (val: string) => {
    if (selectedCard === val) {
      setSelectedCard(undefined);
      return;
    }
    setSelectedCard(val);
  };

  const onContinue = () => {
    navigate(`/company-details/${activeTab}/${selectedCard}`);
  };

  const onLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      <div className="bg-gradient-to-b from-[#053EA4] from-32.88% to-[#138EDC] to-73.61% p-4 md:p-12 xl:p-16">
        <img
          src={LogoHeader}
          alt="Pemant.in"
          className="w-[100px] md:w-[150px] xl:w-[180px] cursor-pointer"
          onClick={() => onLinkClick("/")}
        />
        <div className="flex flex-col items-center text-basic-white mt-8 md:mt-20 xl:mt-28 mx-auto w-[70%] text-center space-y-3 md:space-y-6 ">
          <div className="text-[28px] md:text-[36px] font-bold ">
            Unlock your cashflows with Pemant.
          </div>
          <div className="text-lg md:text-xl font-semibold">
            Have a chat with our team to check how we can help you grow sales
            and reduce credit.
          </div>
        </div>
      </div>
      <div className="p-4 md:p-12 xl:p-16">
        <div className="font-semibold">Select Solution Type</div>
        <div>Select which type of solution you would like to avail.</div>
        <div className="flex my-3 md:my-6 font-bold text-xl md:text-2xl cursor-pointer">
          <div
            className={`w-[50%] border border-grey-50 py-2 md:py-3 text-center rounded-s-md ${
              activeTab === "buyer"
                ? "!border-blue-50 text-blue-50 ring-blue-50"
                : "border-r-0"
            }`}
            onClick={() => onTabClick("buyer")}
          >
            Buyer
          </div>
          <div
            className={`w-[50%] border border-grey-50 py-2 md:py-3 text-center rounded-e-md ${
              activeTab === "seller"
                ? "!border-blue-50 text-blue-50 ring-blue-50"
                : "border-l-0"
            }`}
            onClick={() => onTabClick("seller")}
          >
            Seller
          </div>
        </div>
        {activeTab === "buyer" ? (
          <div className="space-y-4 md:space-y-8 mt-8 md:mt-16">
            <Card
              img={BuyerVendor}
              text="Vendor Financing"
              onClick={onCardClick}
              value={selectedCard}
            />
            <Card
              img={BuyerPurchase}
              text="Purchase Invoice Discounting"
              onClick={onCardClick}
              value={selectedCard}
            />
          </div>
        ) : (
          <div className="space-y-4 md:space-y-8 mt-8 md:mt-16">
            <Card
              img={SellerManufacturer}
              text="Manufacturer- Dealer Sales Invoice Discounting"
              onClick={onCardClick}
              value={selectedCard}
            />
            <Card
              img={SellerDistributer}
              text="Distributor – Retailer Sales Invoice Discounting"
              onClick={onCardClick}
              value={selectedCard}
            />
          </div>
        )}
        {selectedCard && (
          <Button onClick={onContinue} className="mt-5 md:mt-10">
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default SolutionType;
