import Main from "./components/Main";
import RightSolution from "./components/RightSolution";
import TailoredBusiness from "./components/TailoredBusiness";
import WhyPemant from "./components/WhyPemant";
import FAQ from "./components/FAQ";

const Home = () => {
  return (
    <div className="flex-1">
      <Main />
      <RightSolution />
      <TailoredBusiness />
      {/* <WhyPemant /> */}
      <FAQ />
    </div>
  );
};

export default Home;
