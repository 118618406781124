import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
type MenuProps = {
  children: React.ReactNode;
  label: string;
  className?: string;
};

const Menu = ({ children, label, className = "" }: MenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <div className={`${className}`} onClick={toggleMenu}>
        {label}
      </div>
      {isOpen && (
        <div
          className="absolute left-0 top-full mt-4 w-max min-w-48 bg-basic-white shadow-lg rounded-lg py-2 md:py-4"
          style={{ animation: "fadeInUp 0.3s ease-in-out" }}
        >
          <ul onClick={() => setIsOpen(false)}>{children}</ul>
        </div>
      )}
    </div>
  );
};

export default Menu;
