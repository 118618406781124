import CredoBull from "src/assets/images/about/credo-bull.svg";
import Dnb from "src/assets/images/about/dun-bradstreet.png";
import Ciie from "src/assets/images/about/CIIE.png";
import Bharat from "src/assets/images/about/BHARAT II.png";
import LogoHeader from "src/assets/icons/logo-black.svg";

const MILESTONE1 = () => {
  return (
    <>
      <div>Incubated at IIM Ahmedabad under the</div>
      <div className="flex  justify-center items-center">
        Financial Inclusion Labs Program.
      </div>
    </>
  );
};
const MILESTONE2 = () => {
  return (
    <>
      <div>
        Program sponsored by Bill and Melinda Gates Foundation, Omidyar Network,
      </div>
      <div className="flex  justify-center items-center">
        Michael and Susan Dell Foundation and J.P Morgan.
      </div>
    </>
  );
};
const MILESTONE3 = () => {
  return (
    <>
      <div>
        Exclusive technology assignment deal closed for Credochain Underwriting
      </div>
      <div className="flex  justify-center items-center">
        Stack to power Dun & Bradstreet Credit Analytics Platform.
      </div>
    </>
  );
};
const MILESTONE4 = () => {
  return (
    <>
      <div>Launch of Deep Tier Supply Chain</div>
      <div className="flex  justify-center items-center">Finance.</div>
    </>
  );
};
export const milestones = {
  0: {
    year: "2019",
    heading: "Credochain was Established",
    image: CredoBull,
    classes: "",
  },
  1: {
    year: "2020",
    heading: <MILESTONE1 />,
    image: Ciie,
    classes: "",
  },
  2: {
    year: "2021",
    heading: <MILESTONE2 />,
    image: Bharat,
    classes: "",
  },

  3: {
    year: "2022",
    heading: <MILESTONE3 />,
    image: Dnb,
    classes: "",
  },
  4: {
    year: "2023",
    heading: <MILESTONE4 />,
    image: LogoHeader,
    classes: "h-26 p-8",
  },
};
