import { Container } from "src/components";

import * as config from "../config";

export type CardProps = {
  heading: string;
  details: string;
  img: string;
};

const Card = ({ heading, details, img }: CardProps) => {
  return (
    <div className="p-4 lg:p-6 bg-basic-white grid grid-cols-12 rounded-lg">
      <div className="col-span-2 md:col-span-3 lg:col-span-2 ">
        <img alt={heading} src={img} />
      </div>
      <div className="col-span-10 md:col-span-9 lg:col-span-10 pl-2 md:pl-0">
        <div className="text-2xl md:text-3xl font-bold text-blue-50">
          {heading}
        </div>
        <div className="text-sm">{details}</div>
      </div>
    </div>
  );
};

const TailoredBusiness = () => {
  return (
    <div className="bg-gradient-to-b from-[#1675EB] from-1.17% to-[#0A3689] to-100% ">
      <Container className="md:pb-40">
        <div className="text-4xl md:text-6xl py-0 md:py-6 font-semibold text-basic-white mb-4 md:mb-12">
          Tailored business finance solutions.
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-20">
          {config.tailoredBusiness?.map((el:CardProps, index:number) => {
            return (
              <Card
                heading={el.heading}
                details={el.details}
                key={`tailored-${index}`}
                img={el.img}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default TailoredBusiness;
