import React from "react";

type InputProps = {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type?: React.HTMLInputTypeAttribute | undefined;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  maxLength?:number;
  required?:boolean
  onKeyDown?:React.KeyboardEventHandler<HTMLInputElement>;
};

const Input = ({
  value,
  onChange,
  type = "text",
  disabled = false,
  placeholder = "",
  maxLength,
  name,
  required ,
  onKeyDown
}: InputProps) => {
  return (
    <input
      type={type}
      onChange={onChange}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      className="w-full md:w-[300px] xl:w-[400px]  p-2 border border-grey-50 rounded-lg mt-1"
      name={name}
      maxLength={maxLength}
      required={required}
      onKeyDown={onKeyDown}
    />
  );
};

export default Input;
