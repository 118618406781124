import { Container, Navbar } from "src/components";
import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import One from "src/assets/images/solutions/subprogram.svg";

const CustomizableScf = () => {
  return (
    <div className="bg-gradient-to-r from-[#3882E0] from-22.47% to-[#003CA8] to-71.92% relative min-h-[95vh]">
      <Container>
        <Navbar />
        <div className="grid grid-cols-1 md:grid-cols-2 mt-12 md:mt-32">
          <div>
            <div className="text-3xl md:text-4xl text-basic-white font-semibold">
              Customizable SCF Programs
            </div>
            <div className="text-basic-white mt-4 md:mt-12 text-2xl">
              <div>
                Pemant Platform enables Lenders and Financial Institutions to
                offer customized Deep Tier Supply Chain Financing programs in a
                way that meets requirements of both buyers and sellers at at
                same time manage risks for Lenders
              </div>
              <ul className="mt-3 text-sm md:text-md">
                Pemant super flexible solutions offers
                <li className="mt-2">• Interest sharing via Subvention </li>
                <li className="mt-2">• Interest-free periods</li>
                <li className="mt-2">• Tenure Extension Options</li>
                <li className="mt-2">• Cashback incentives & more!</li>
              </ul>
            </div>
          </div>
          <div className="hidden md:flex justify-end">
            <img src={One} className="ml-2" alt="Customizable SCF Programs" />
          </div>
        </div>
      </Container>
      <div className="w-full h-auto md:h-[220px] absolute bottom-0 z-0">
          <img
            src={FAQBg}
            alt="Full Screen"
            className="w-full h-full object-cover"
          />
        </div>
    </div>
  );
};

export default CustomizableScf;
