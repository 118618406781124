import { useNavigate } from "react-router-dom";
import { Blog } from "./Blogs.layout";

type RecentPostProps = {
  blogs: Blog[];
};

const RecentPostPreview = ({ img, heading, date, _id }: Blog) => {
  const navigate = useNavigate();

  const onPostClick = () => {
    navigate(`/blogs/${_id}`);
  };

  return (
    <div
      className="p-3 md:p-4 space-y-2 md:space-y-4 cursor-pointer"
      onClick={onPostClick}
    >
      <img src={img} alt={heading} className="rounded-lg" />
      <div className="font-semibold">{heading}</div>
      <div className="text-[#575757B5] text-sm">{date}</div>
    </div>
  );
};
const RecentPost = ({ blogs }: RecentPostProps) => {
  return (
    <div className="bg-[#EBF3FD] rounded-lg">
      <div className="border-b-2 border-grey-50 p-3 md:p-4 text-lg md:text-xl font-semibold">
        Recent Post
      </div>
      <div className="flex flex-col gap-y-0 md:gap-y-4">
        {blogs.map((blog) => {
          return <RecentPostPreview {...blog} />;
        })}
      </div>
    </div>
  );
};

export default RecentPost;
