type MenuItemProps = {
  children: React.ReactNode;
  className?: string;
  isBg?: boolean;
  onClick?: () => void;
};

const MenuItem = ({
  children,
  className = " ",
  isBg = true,
  onClick,
}: MenuItemProps) => {
  return (
    <li
      className={`${className}  text-basic-black px-3  md:px-6 cursor-pointer ${
        isBg ? "hover:bg-blue-50/10 py-1 md:py-2 " : ""
      }`}
      onClick={onClick}
    >
      {children}
    </li>
  );
};

export default MenuItem;
