import { Container } from "src/components";
import Two from "src/assets/images/solutions/buyer-dash.svg";

const AutomatedInvoice = () => {
  return (
    <div className="bg-[#EBF3FD] py-4 md:py-28">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="hidden md:flex">
            <img src={Two} alt="Automated Invoice Processing" />
          </div>
          <div>
            <div className="font-semibold text-2xl md:text-4xl">
              Automated Invoice Processing
            </div>
            <div className="mt-4 text-xl md:text-2xl">
              Pemant platform uses OCR tools for seamless invoice processing.
              <ul className="mt-3">
                <li className="mt-2 flex items-start">
                  <span className="mr-2">•</span>
                  <span>Robust Automation: Streamlines operations, expediting financing.</span>
                </li>
                <li className="mt-2 flex items-start">
                  <span className="mr-2">•</span>
                  <span>OCR Integration: Enhances efficiency in invoice processing.</span>
                </li>
                <li className="mt-2 flex items-start">
                  <span className="mr-2">•</span>
                  <span>Streamlined Operations: Eliminates manual interventions.</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AutomatedInvoice;
