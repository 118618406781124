import { Container } from "src/components";
import Three from "src/assets/images/solutions/Lender.svg";

const EffortlessLending = () => {
  return (
    <div className="bg-gradient-to-b from-[#3882E0] from-0% to-[#00379E] to-59.67% text-basic-white py-4 md:py-28">
      <Container>
        <div className="text-3xl md:text-4xl font-semibold">
          Effortless Lender Financing
        </div>
        <div className="text-2xl md:text-3xl">
          Pemant simplifies financing for lenders, minimizing manual touchpoints
          in invoice financing. This automation speeds up the process, ensuring
          a seamless experience.
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-12">
          <div className="hidden md:block w-[500px]">
            <img src={Three} alt="Dynamic Risk Assessment" />
          </div>
          <div>
            <div className="text-3xl md:text-4xl font-semibold">
              Dynamic Risk Assessment
            </div>
            <div className=" mt-6 text-xl md:text-2xl">
              Pemant platform enables lenders to customize Deep Tier Supply
              Chain Finance (SCF) programs, including features like
              <ul className="space-y-4 mt-2">
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  <span>Integrated Credit Analytics Platform: Real-time data aids dynamic risk assessment.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  <span>Proactive Risk Mitigation: Swiftly identify and address potential SCF program risks.</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">•</span>
                  <span>Effective Financial Safeguarding: Ensure protection of financial interests through proactive risk management.</span>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EffortlessLending;
