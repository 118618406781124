import React, { ReactNode, useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import LogoBlack from "src/assets/icons/logo-black.svg";
import { useNavigate } from "react-router-dom";
import * as config from "./config";
import { Accordion } from "src/components/atoms";
import { NavbarProps } from ".";
import LogoHeader from "src/assets/images/home/pemant.svg";

const MobileNavbar = ({ theme }: NavbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      navbarRef.current &&
      !navbarRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onLinkClick = (link: string) => {
    navigate(link);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsOpen(false);
  };

  return (
    <div className="relative z-[99999]" ref={navbarRef}>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-[99998]"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
      <div
        className={`fixed inset-y-0 right-0 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out bg-basic-white w-64 h-screen z-[99999]`}
      >
        <nav className="mt-4 px-2 overflow-y-auto h-full">
          {config.navbarMenuItems.map((item) => (
            <Accordion
              key={item.heading}
              summary={item.heading}
              details={
                <MenuLinks links={item.childrens} onLinkClick={onLinkClick} />
              }
            />
          ))}
        </nav>
      </div>
      <div className="fixed top-0 left-0 right-0 z-[99997] flex items-center justify-between  p-4 bg-blue-50">
        <img
          src={LogoHeader}
          className="w-[100px] md:w-[150px] xl:w-[180px] cursor-pointer"
          alt="Pemant.in"
          onClick={() => onLinkClick("/")}
        />
        <GiHamburgerMenu
          onClick={toggleNavbar}
          className={`${
            theme === "dark" ? "text-basic-black" : "text-basic-white"
          } cursor-pointer`}
          size={26}
        />
      </div>
    </div>
  );
};

type MenuLinkProps = {
  links: Links[];
  onLinkClick: (link: string) => void;
};

type Links = {
  heading: ReactNode;
  link: string;
};

const MenuLinks = ({ links, onLinkClick }: MenuLinkProps) => {
  return (
    <>
      {links.map((link) => {
        return (
          <>
            <div
              onClick={() => onLinkClick(link.link)}
              className="pl-4 my-3 text-sm"
            >
              {link.heading}
            </div>
          </>
        );
      })}
    </>
  );
};

export default MobileNavbar;
