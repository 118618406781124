import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LogoHeader from "src/assets/images/home/pemant.svg";
import { Button, Input, Label, Select } from "src/components";
import { useNavigate } from "react-router-dom";

type CompanyDetail = {
  activeBuyerCount: string;
  annualTurnover: string;
  companyName: string;
  emailId: string;
  gstIn: string;
  mobileNumber: string;
  programType: string;
  userType: string;
};

const options = [
  { value: "Less than 5 crores", label: "Less than ₹ 5 crores" },
  { value: "5-20 crores", label: "₹ 5 - 20 crores" },
  { value: "20-50 crores", label: "₹ 20 - 50 crores" },
  { value: "Greater than 50 crores", label: "₹ Greater than ₹ 50 crores" },
];

const programTypeMap = {
  "Vendor Financing": "vendor financing",
  "Purchase Invoice Discounting": "purchase invoice discounting",
  "Manufacturer- Dealer Sales Invoice Discounting": "manufacturer",
  "Distributor – Retailer Sales Invoice Discounting": "distributor",
};

const CompanyDetails = () => {
  const [companyDetails, setCompanyDetails] = useState<
    Omit<CompanyDetail, "programType" | "userType">
  >({
    activeBuyerCount: "",
    annualTurnover: "",
    companyName: "",
    emailId: "",
    gstIn: "",
    mobileNumber: "",
  });

  const { programType, userType } = useParams<{
    programType: string;
    userType: string;
  }>();
  const navigate = useNavigate();

  const onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setCompanyDetails((prevVal) => ({ ...prevVal, [name]: value }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
    }
};

  const onLinkClick = (link: string) => {
    navigate(link);
  };

  const validate = () => {
    if (companyDetails.companyName.length < 3) {
      alert("Please Enter Correct company name");
      return false;
    }
    if (companyDetails.gstIn.length < 15) {
      alert("Please Enter Correct GST Number");
      return false;
    }
    if (companyDetails.annualTurnover.length < 3) {
      alert("Please Enter Correct Annual Turnover");
      return false;
    }
    if (!(/^\d+$/.test(companyDetails.activeBuyerCount))) {
      alert("Please Enter Correct Active B2B Buyer/Customer Count");
      return false;
    }
    if (!(companyDetails.mobileNumber.length === 10)) {
      alert("Please Enter Correct 10 Digit Mobile Number");
      return false;
    }
    return true;
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); 
    if (validate()) {
      const dataToSend: CompanyDetail = {
        ...companyDetails,
        programType:
          programTypeMap[
          decodeURI(programType || "") as keyof typeof programTypeMap
          ],
        userType: decodeURI(userType || ""),
      };
      axios
        .post(
          "https://appd.credochain.com/product-management/v1/blog/new-inquiry",
          dataToSend
        )
        .then(({ data }) => {
          if (data.code === 200) {
            alert("Application Submitted Successfully");
          }
          setCompanyDetails({
            activeBuyerCount: "",
            annualTurnover: "",
            companyName: "",
            emailId: "",
            gstIn: "",
            mobileNumber: "",
          });
        })
        .catch((err) => {
          console.error(err.response.data.errors);
          alert(err.response.data.errors);
        });
    }
  };

  return (
    <form onSubmit={onSubmit} className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      <div className="bg-gradient-to-b from-[#053EA4] from-32.88% to-[#138EDC] to-73.61% p-4 md:p-12">
        <img
          src={LogoHeader}
          alt="Pemant.in"
          className="w-[100px] md:w-[150px] xl:w-[180px] cursor-pointer"
          onClick={() => onLinkClick("/")}
        />
        <div className="flex flex-col items-center text-basic-white mt-8 md:mt-20 xl:mt-28 mx-auto w-[70%] text-center space-y-3 md:space-y-6 ">
          <div className="text-[30px] md:text-[36px] font-bold ">
            Unlock your cashflows with Pemant.
          </div>
          <div className="text-lg md:text-xl font-semibold">
            Have a chat with our team to check how we can help you grow sales
            and reduce credit.
          </div>
        </div>
      </div>
      <div className="p-4 md:p-12 xl:p-16">
        <div className="font-bold text-xl md:text-2xl">
          Your Company Details
        </div>
        <div className="text-lg md:text-xl">
          Please fill in your details below and we'll be in touch shortly.
        </div>
        <div className="mt-8 space-y-4">
          <div>
            <Label>Name of Company/ Firm*</Label>
            <Input
              value={companyDetails.companyName}
              onChange={onInputChange}
              name="companyName"
              required
            />
          </div>
          <div>
            <Label>GST Number*</Label>
            <Input
              value={companyDetails.gstIn}
              onChange={onInputChange}
              name="gstIn"
              required
            />
          </div>
          <div>
            <Label>Annual Turnover Range*</Label>
            <Select
              options={options}
              value={companyDetails.annualTurnover}
              onChange={onInputChange}
              placeholder="Select Annual Turnover Range"
              name="annualTurnover"
              required
            />
          </div>

          <div>
            <Label>Number of Active B2B Buyer/ Customer*</Label>
            <Input
              value={companyDetails.activeBuyerCount}
              onChange={onInputChange}
              name="activeBuyerCount"
              type="number"
              required
              onKeyDown={handleKeyDown}
            />
          </div>
          <div>
            <Label>Phone Number*</Label>
            <Input
              value={companyDetails.mobileNumber}
              onChange={onInputChange}
              name="mobileNumber"
              maxLength={10}
              type="number"
              required
            />
          </div>
          <div>
            <Label>Email ID*</Label>
            <Input
              value={companyDetails.emailId}
              onChange={onInputChange}
              name="emailId"
              type="email"
              required
            />
          </div>
        </div>
        <Button type="submit" className="mt-5 md:mt-12">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default CompanyDetails;
