import { CardProps } from "../components/TailoredBusiness";
import Tailored1 from "src/assets/images/home/tailored-1.svg";
import Tailored2 from "src/assets/images/home/tailored-2.svg";
import Tailored3 from "src/assets/images/home/tailored-3.svg";
import Tailored4 from "src/assets/images/home/tailored-4.svg";
import { Button } from "src/components";

const FAQANSWER1 = () => {
  return (
    <div>
      <div className="font-normal mt-3">
        <span className="font-semibold">For Suppliers and Distributors</span> -
        <span className="ml-2">
          Pemant facilitates the conversion of trade receivables into instant
          cash without assuming collection risk. Lenders, partnered with Pemant,
          fund buyer payments based on confirmed GST invoices within one day,
          off the balance sheet. This aids in reducing debt and trade
          receivables, ultimately boosting sales without extending credit.
        </span>
      </div>
      <div className="font-normal mt-2 ">
        <span className="font-semibold">For B2B Buyers and Retailers</span> -
        <span className="ml-2">
          Pemant offers interest-free payment terms for purchasing goods,
          providing cost-free liquidity solutions. B2B Buyers can enhance profit
          margins through cashback incentives for early payments, with rates
          reaching up to 1.5%, thereby improving margins for both B2B buyers and
          Retailers.
        </span>
      </div>
    </div>
  );
};
const FAQANSWER2 = () => {
  return (
    <div>
      <div className="font-normal mt-2 ">
        Pemant's solutions stand apart from conventional loans, serving as
        receivable management strategies to reduce credit and bolster sales.
      </div>
      <div className="font-normal mt-2 ">
        Typically, business loans demand collateral and borrower agreements,
        escalating debt burdens. Unsecured loans carry steep costs, with APR
        rates ranging from 18-24%.
      </div>
    </div>
  );
};
const FAQANSWER3 = () => {
  return (
    <div>
      <div className="font-normal mt-2 ">
        Costs, usually ranging from 1.2% to 1.5% monthly, can be split between
        sellers and buyers. Pemant's hybrid interest programs facilitate shared
        costs. Alternatively, sellers can also offer interest-free periods to
        buyers through our platform.
      </div>
    </div>
  );
};

const FAQANSWER4 = () => {
  return (
    <div>
      <div className="font-normal mt-2 ">
        At Pemant, transparency is paramount. Besides the fees outlined in the
        KFS, there are no additional charges, nor do we impose unexpected
        foreclosure fees or hidden costs later on. You only pay interest on the
        utilized amount and for the period you've used it—nothing more,
        guaranteed.
      </div>
    </div>
  );
};

const FAQANSWER5 = () => {
  return (
    <div>
      <div className="font-normal mt-2 ">
        There are absolutely no charges for using our Supplier and Buyer Digital
        Platform. Check out demos for both the Supplier and Buyer Platforms.
        <a
          href="https://drive.google.com/file/d/1xQboiGnhKgbT3U3HB6Y2iyubRxUhYPvH/view?usp=drive_link"
          className="hover:text-blue-100 ml-3 hover:underline text-blue-100"
        >
          Click here
        </a>
      </div>
    </div>
  );
};
export const FAQs = [
  {
    ques: "Q1. How will Pemant help my business ?",
    ans: <FAQANSWER1 />,
  },
  {
    ques: "Q2. How is a Pemant Solutions different from a business loans?",
    ans: <FAQANSWER2 />,
  },
  {
    ques: "Q3. What is the cost of availing ?",
    ans: <FAQANSWER3 />,
  },
  {
    ques: "Q4. Does Pemant have any undisclosed/hidden expenses?",
    ans: <FAQANSWER4 />,
  },
  {
    ques: "Q5. Are there any charges associated with using the platform?",
    ans: <FAQANSWER5 />,
  },
];

export const tailoredBusiness: CardProps[] = [
  {
    heading: "White Goods Distributors",
    details:
      "Our program offers upfront payments, improving cash flow and reducing financial strain, ensuring smooth inventory management and effective business operations.",
    img: Tailored1,
  },
  {
    heading: "Battery & Invertor Distributors",
    details:
      "Timely funds enhance inventory management, optimizing supply chain efficiency and meeting customer demands effectively for sustained business growth.",
    img: Tailored2,
  },
  {
    heading: "Apple Distributors",
    details:
      "Smooth procurement processes facilitated by timely payments ensure product availability, strengthen cash flow, and streamline operations for consistent business stability and growth.",
    img: Tailored3,
  },
  {
    heading: "Solar Panel Manufacturer ",
    details:
      "Upfront support for raw material procurement strengthens financial stability, ensuring consistent production and market supply, while streamlined operations drive efficiency and long-term growth in renewable energy.",
    img: Tailored4,
  },
];
