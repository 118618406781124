import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

export type NavbarProps = {
  theme?: "light" | "dark";
};

const Navbar = ({ theme = "light" }: NavbarProps) => {
  return (
    <>
      <div className="hidden md:block">
        <DesktopNavbar theme={theme} />
      </div>
      <div className="md:hidden">
        <MobileNavbar theme={theme} />
      </div>
    </>
  );
};

export default Navbar;
