import { TeamCardType } from ".";
import AmanPandey from "src/assets/images/team/aman-pandey.jpg";
import Vaibhav from "src/assets/images/team/vaibhav.svg";
import Chandra from "src/assets/images/team/chandra.svg";
import kishan from "src/assets/images/team/kishan.svg";
import Danish from "src/assets/images/team/Danish.svg";
import Sanskar from "src/assets/images/team/sanskar.svg";
import Vaishali from "src/assets/images/team/vaishali.svg";
import Ashutosh from "src/assets/images/team/ashutosh.svg";
import Anjali from "src/assets/images/team/Anjali.svg";
import Chandani from "src/assets/images/team/Chandani.svg";
export const teamsData: TeamCardType[] = [
  {
    description:
      "Former Executive Director at Deloitte Consulting with 11 years of experience. Advised Bandhan, Fino, ESAF, and India Post Payments Bank on RBI licensing and strategic transitions into banks. Recently monetized Credochain Stack by selling rights to Dun & Bradstreet.",
    designation: "Founder & CEO",
    img: Vaibhav,
    name: "Vaibhav Anand",
    linkedInlink: "https://www.linkedin.com/in/vaibhavanand/",
  },
  {
    description:
      "Chandra Prakash Joshi drives India's growth by scaling the India Supply Chain Finance business. With 16+ years of experience in Sales, Marketing, and Growth, he has worked for MNCs like D&B and Standard Chartered Bank. He holds an MBA from IIEST Shibpur and a CFA from ICFAI.",
    designation: "Business Head",
    img: Chandra,
    name: "Chandra Prakash Joshi",
    linkedInlink:
      "https://www.linkedin.com/in/chandra-prakash-joshi-mba-cfa-icfai-mfa-0a9ab822/",
  },
  {
    description:
      "Kishan Kumar, a Senior Software Engineer (Back-end Engineer) with 3+ years of experience, excels in developing robust software solutions. His fintech expertise and innovative approach significantly contribute to advancing company growth and ensuring project success.",
    designation: "Senior software developer",
    img: kishan,
    name: "KISHAN KUMAR",
    linkedInlink: "https://www.linkedin.com/in/rdkishankumar/",
  },
  {
    description:
      "Vaishali is an accomplished Frontend Developer at Pemant with over 4 years of experience in creating dynamic and user-friendly web and mobile applications. Specializing in React.js and React Native, she excels in building responsive and high-performance interfaces for both web and mobile platforms.",
    designation: "Frontend Developer",
    img: Vaishali,
    name: "Vaishali Pathak",
    linkedInlink:
      "https://www.linkedin.com/in/vaishali-pathak-122295158?utm source=share&utm_camp aign=share via&utm content=profile&utm medium ios app",
  },
  {
    description:
      " Aman Pandey, a proficient developer boasting three years of industry experience, demonstrates exceptional expertise in designing and implementing Loan Management Systems (LMS) customized to meet the intricate requirements of supply chain financing at Credochain.",
    designation: "Backend Developer",
    img: AmanPandey,
    name: "Aman Pandey",
    linkedInlink: "https://www.linkedin.com/in/tayal-nikhil/",
  },
  {
    description:
      "Danish is a proficient software developer with over 1.5 years of expertise in web applications within fintech startups. Graduating from BITSMesra, he excels in crafting And secure, scalable, and user-friendly web solutions, driving innovation and growth.",
    designation: "Software Developer",
    img: Danish,
    name: "Danish Abshar",
    linkedInlink: "https://www.linkedin.com/in/danish-abshar",
  },

  {
    description:
      "Sanskar Rawat, a diligent Software Developer adept in backend operations, harnesses the power of data-driven insights. With a year's experience and a degree in Data Science, he seamlessly blends technical prowess and analytical acumen to craft robust solutions that propel organizational growth.",
    designation: "Backend Developer",
    img: Sanskar,
    name: "SANSKAR RAWAT",
    linkedInlink:
      "https://www.linkedin.com/in/sanskar-rawat?utm source=share&utm campaign=share via&utm content=profile&utm_medium=ios_app",
  },

  {
    description:
      "Ashutosh Kumar, a committed and rising Backend Developer at CredoChain, adeptly improves backend functionalities and optimizes processes within the fintech sector. Leveraging his diverse skill set and fervor for backend development, he endeavors to propel Credochain towards operational excellence and foster innovation in deep-tier supply chain financing",
    designation: "Backend Developer",
    img: Ashutosh,
    name: "Ashutosh Kumar",
    linkedInlink:
      "https://www.linkedin.com/in/ashutosh-kumar-a77724234?utm source=share&utm_campaign are via&utm content=profile&utm_medium=ios_app",
  },

  {
    description:
      "Chandni Meena, a proficient customer service executive at Credochain, spearheads the C.S Team . Holding an MA from the University of DR. Bhimrao Ambedkar University Agra, Chandni seamlessly manages customer relations by attentively addressing queries, resolving issues promptly, and fine-tuning services to meet evolving client needs, ensuring utmost satisfaction and loyalty.",
    designation: "Customer service executive",
    img: Chandani,
    name: "CHANDNI MEENA",
    linkedInlink:
      "https://www.linkedin.com/in/chandni-meena-6557b225a?utm source=share&utm campaign=sh are via&utm content=profile&utm_medium=android_app",
  },
  {
    description:
      "Anjali, at Credochain, is a seasoned Credit Manager specializing in Supply Chain Finance. With an M.Sc. in Financial Economics.",
    designation: "Credit Manager",
    img: Anjali,
    name: "ANJALI SINGH",
    linkedInlink: "https://www.linkedin.com/in/aniali94/",
  },
];
