import Manufacturer from "src/assets/icons/navbar/manufacturer.svg";
import Distributor from "src/assets/icons/navbar/distributer.svg";
import VendorFinancing from "src/assets/icons/navbar/vendor-financing.svg";
import PurchaseInvoiceDiscounting from "src/assets/icons/navbar/purchase-invoice-discounting.svg";
import FinancialInstitutions from "src/assets/icons/navbar/financial-institutions.svg";

export const ourProducts = [
  {
    heading: "Manufacturer- Dealer Sales Invoice Discounting ",
    subheading:
      "Unlock your receivables and get instantly paid for sales invoices",
    image: Manufacturer,
    link: "manufacturer-supplier",
  },
  {
    heading: "Distributor - Retailer Sales Invoice Discounting",
    subheading:
      "Unlock your receivables and get instantly paid for sales invoices",
    image: Distributor,
    link: "distributor-retailer",
  },
  {
    heading: "Vendor Financing",
    subheading: "Offer flexible early payment options to Vendors",
    image: VendorFinancing,
    link: "vendor-financing",
  },

  {
    heading: "Purchase Invoice Discounting",
    subheading: "Flexible liquidity for making supplier payments",
    image: PurchaseInvoiceDiscounting,
    link: "purchase-invoice-discounting",
  },
  {
    heading: "Financial Institutions",
    subheading:
      "Participate and Fund trade transactions for high growth value chains",
    image: FinancialInstitutions,
    link: "financial-institutions",
  },
];

export const navbarMenuItems = [
  {
    heading: "Our Solutions",
    childrens: ourProducts.map((el) => ({
      heading: el.heading,
      link: `/solutions/${el.link}`,
    })),
  },
  {
    heading: "Company",
    childrens: [
      {
        heading: "About Us",
        link: "/about-us",
      },
      {
        heading: "Our Team",
        link: "/our-team",
      },
    ],
  },
  {
    heading: "Resources",
    childrens: [
      {
        heading: "Blogs",
        link: "/blogs",
      },
    ],
  },
  {
    heading: "Contact",
    childrens: [
      {
        heading: <div>
          <a
            href="mailto:support@credochain.com"
            className="hover:text-blue-50 hover:underline"
          >
            support@credochain.com
          </a></div>,
        link: "#",
      },
      {
        heading: <div>
          <a
            href="tel:+91 11-41716192"
            className="hover:text-blue-50 hover:underline"
          >
            +91 11-41716192
          </a>
        </div>,
        link: "#",
      },
      {
        heading: "Get Started",
        link: "/solution-type",
      },
    ],
  },
  {
    heading: "Login",
    childrens: [
      {
        heading: <div>
          <div>Supplier Dashboard</div>
          <div className="text-xs text-blue-100 hover:text-blue-100 hover:underline">
            <a href="https://seller.pemant.in/" target="_blank" rel="noreferrer">seller.pemant.in</a>
          </div>
        </div>,
        link: "#",
      },
    ],
  },
];
