import { BiLogoLinkedin } from "react-icons/bi";
import { TeamCardType } from ".";

const TeamCard = ({
  description,
  designation,
  img,
  name,
  linkedInlink,
}: TeamCardType) => {
  return (
    <div className="shadow-lg rounded-xl flex flex-col justify-between h-full">
      <div className="flex flex-col justify-start items-center p-4">
        <img src={img} alt={name} className="h-[220px] w-[220px]" />
        <div className="uppercase font-semibold text-xl md:text-2xl mt-4">
          {name}
        </div>
        <div className="text-blue-50 font-bold text-xl md:text-2xl mt-2">
          {designation}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-4 shadow-inner rounded-3xl py-4 flex-grow">
        <div className="text-left max-w-[80%] mt-4">{description}</div>
      </div>
      <div className="p-4">
        <a
          href={linkedInlink}
          className="flex justify-end items-center text-blue-50 text-xl md:text-2xl cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          <span>Connect On</span>
          <div className="bg-blue-50 rounded-full h-[30px] w-[30px] flex justify-center items-center ml-2">
            <BiLogoLinkedin color="#fff" className="mb-0.5" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default TeamCard;
