import React from "react";
import { Container, Navbar } from "src/components";

const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <Navbar theme="dark" />
      </Container>
      <div className="bg-[#EBF3FD]">
        <Container>
          <div className="font-bold mb-4">CREDOCHAIN PRIVACY POLICY</div>
          This Privacy Policy (“Privacy Policy”) outlines Credochain’s
          (“Credochain”, “Pemant” “we” or “us”) practice in relation to the
          storage, use, processing, and disclosure of personal data that you
          have chosen to share with us when you access our website{" "}
          <a href="https://pemant.in">(https://pemant.in)</a>or our mobile application
          Pemant (collectively, the “Platform”), or personal data that Pemant
          may have access to in relation to your use of the Services provided by
          us. Please read the terms of this Privacy Policy carefully in
          consonance with the Terms and Conditions as available on the Platform
          to understand our practices regarding your Personal Information and
          how we will treat it. This Privacy Policy is applicable to
          Credochain’s business, Platform (defined below) and Services (defined
          below).
          <div className="space-y-6 mt-12">
            <div>
              At Credochain, we are committed to protecting your personal
              information and respecting your privacy. You can, at any point of
              time, choose to edit/ modify or delete/ withdraw any of your
              Personal Information shared for use of the Platform or Services or
              opt-out of receiving non-essential (promotional and/ or
              marketing-related) communications after setting up an account with
              us by writing at support@credochain.com.
            </div>
            <div>
              For the purposes of this Privacy Policy, the users of the Platform
              or Services may be any person or entity who accesses or uses or
              avails our Platform or Services (“User” or “you” or “your”). By
              visiting our Platform and/ or availing the Services provided by us
              (“Services”), you agree to be bound by the terms and conditions of
              this Privacy Policy.
            </div>
            <div>
              This Privacy Policy sets out the basis on which any personal
              information Pemant collect from you, Pemant collect about you, or
              that you provide to us, including your Personal Information
              (defined below) and Sensitive Personal Information as defined
              under Rule 3 of Information Technology (Reasonable security
              practices and procedures and sensitive personal data or
              information) Rules, 2011 will be processed by us.
            </div>
          </div>
          <div>
            <div className="font-bold mt-8 mb-4">THE DATA WE COLLECT:</div>
            1. Personal Information/ Data: When you access our Platform and/ or
            avail our Services, Pemant may collect, use, store and share
            different kinds of personal information about you, in order to
            provide you a safe, efficient, smooth and customized experience, and
            further to provide you the Services. This personal information
            includes the following information:
            <br />
            1.1. Location: Pemant collects, transmits, and stores location data
            on our secured servers(https://pemant.credochain.com) for the
            purpose of checking serviceability, fraud prevention, risk
            assessment for credit scoring, expediting KYC, and providing
            personalized servicing;
            <br />
            1.2. Camera and Image Gallery: Pemant collects and stores images and
            photos accessed via the camera and image gallery for onboarding
            purposes, allowing users to click and upload images to complete the
            KYC process. This information is stored on our secured
            servers(https://pemant.credochain.com). It is required for
            hassle-free onboarding.; 
            <br />
            1.3. Identification Information: Data pertaining to your identity
            and profile-related data, such as your first and last name, username
            or similar identifiers, gender, title, purchases made, feedback,
            survey responses, etc.;
            <br />
            1.4. Phone Numbers: Pemant collects and stores phone numbers during
            the application process so that supplier names in the contact list
            can be auto-selected. We securely upload this consented contact
            information to our encrypted server(https://pemant.credochain.com);
            <br />
            1.5. Device Information: Pemant collects, stores, and monitors
            specific information about User's device, including hardware model,
            operating system and version, unique device identifiers like IMEI
            and serial number, user profile information, and mobile network
            information, to uniquely identify the devices and ensure that
            unauthorized devices are not able to act on your behalf to prevent
            fraud. This information is stored on our secured
            servers(https://pemant.credochain.com). It is required for
            hassle-free registration and fraud prevention;
            <br />
            1.6. Web Information: technical data includes IP addresses, browser
            types and versions, time zone settings and locations, operating
            systems, and other technology on the devices you use to access the
            platform; For the purpose of this Privacy Policy, IP or Internet
            Protocol” is the principal set (or communications protocol) of
            digital message formats and rules for exchanging messages between
            computers across a single network or series of interconnected
            networks, using the Internet Protocol Suit (often referred to as
            TCP/ IP). Messages are exchanges as datagrams, also known as data
            packets or just packets.
            <br />
            1.7. Usage data including information about how you use our
            Services; and
            <br />
            1.8. Marketing Information: Marketing and communications data
            includes your preferences in receiving marketing from us and our
            third parties and your communication preferences.
            <br />
            1.9. Payment Information: Transaction data, including details about
            payments to and from you and details of products you have purchased
            or sold;
            <br />
            Non- Personal Information: Pemant collect some non -personal
            information such as anonymous data which we use for  Credit
            Assessment, improvement of the customer service to the users and
            technological advancement of system algorithms underlying the
            Platform. 
            <br />
            2. Transactional Data: Pemant collect, use, store and share
            transactional data to help the User avail Services. Transaction Data
            includes the following information:
            <br />
            2.1. Supplier details including the name and mobile number;
            <br />
            2.2. Customer details, including the name and mobile number;
            <br />
            2.3. Invoice issued to user by supplier;
            <br />
            2.4.Invoice issued by user to its customer; and
            <br />
            2.5 Income details of the user to enhance facility.
            <br />
            <div className="mt-4"></div>
            3. Aggregated Data: Pemant also collect, use, and share aggregated
            data such as statistical or demographic data for any purpose.
            Aggregated data may be derived from your Personal Information but is
            not considered Personal Information under Applicable Law. For
            example, we may aggregate your usage data to calculate the
            percentage of users accessing a specific feature of the Platform.
            <div className="mt-4"></div>
            4. User Account Data: Depending on the third-party platform that you
            may use for accessing our Platform, Pemant may also collect, use,
            store and share your account data such as your email address and
            public profile information such as name, photo, etc.
            <div className="mt-4"></div>
            5. App Data: Additionally, Pemant collects, use, store and share
            information from the App’s metadata after it has been installed by
            the User which includes App name, package name, installed time,
            updated time, version name and version code, for the purpose of
            assessing your credit worthiness and enriching your profile with
            customized loan offers.
            <div className="mt-4"></div>
            6. Pemant collects, use, store, and share your Personal Information,
            Transactional Data and Account Data from time to time after
            explicitly seeking your permission to do so. Subject to the
            foregoing, in general, you can access the Platform without providing
            us any information, except information that is obtained through
            Cookies (defined below).
            <div className="mt-4"></div>
            7. Where Pemant need to collect Personal Information or
            Transactional Data under Applicable Law, or under the terms of the
            arrangement we have with you, and you fail to provide that data when
            requested, we may not be able to perform our obligations under the
            arrangement we have with you or are trying to enter into with you
            (for example, to provide you with features of the Platform). In this
            case, we may have to cancel or limit your access to the Platform,
            but we will notify you if this is the case at the time.
            <div className="mt-4"></div>
            8. In order to expand the scope of our Services, Pemant may from
            time to time, seek additional information from you. Any such
            collection of additional information and documents shall be subject
            to an explicit and purpose specific consent sought from you.
            <div className="mt-4"></div>
            9. You agree and acknowledge that by accessing our Platform/
            availing our Services, and while providing information on the
            Platform, you authorise us, our associate partners, and affiliates
            to contact you via email, phone, or otherwise, to ensure that you
            are aware of all the features of the Services Pemant offer/ you
            avail. Further, please note that you provide us explicit consent to
            contact you even if your number is registered under the do not
            disturb registry with the Telecom Regulatory Authority of India.  
          </div>
          <h1 className="font-bold mt-8 mb-4">DATA SECURITY</h1>
          <ol>
            <li>
              Pemant store your information on Amazon Web Services Cloud or
              similar servers (
              <a href="https://pemant.credochain.com">
                https://pemant.credochain.com
              </a>
              ) based in India. We intend to protect your information/ data and
              to maintain its accuracy as confirmed by you. We implement
              reasonable physical, administrative and technical security
              measures including encryption, firewalls, and socket layer
              technology to protect your Personal Information, Transactional
              Data and User Account Data from unauthorized access, unauthorized
              use and unauthorized disclosure. Such technical security measures
              are in compliance with the security practices and procedures as
              prescribed under the Information Technology Act, 2000 and the
              applicable rules, including the Information Technology (Reasonable
              Security Practices and Procedures and Sensitive Personal Data or
              Information) Rules, 2011. However, you agree and acknowledge that
              the above-mentioned measures do not guarantee absolute protection
              to your Personal Information, Transactional Data and User Account
              Data, and that by accessing our Platform and/ or the Services, you
              agree to assume all risks associated with disclosure of such
              information/ data arising due to breach of firewalls and secure
              server software.
            </li>
            <li>
              Pemant also require that our lending partner, affiliates and other
              third-party service providers to protect your information/ data
              that may be shared by us with any of them from unauthorized
              access, use and disclosure. However, you agree and acknowledge
              that we shall not be liable for their delay/ failure to do so.
            </li>
            <li>
              Where Pemant have given you a password or a One Time Password
              (OTP) which enables you to use or navigate certain parts of the
              Platform, you are responsible for keeping this password
              confidential. We request you not to share the password with
              anyone.
            </li>
            <li>
              Pemant comply with the requirements under the Information
              Technology Act, 2000 and the rules made thereunder in the event of
              a data or security risk.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">DATA RETENTION</h1>
          <ol>
            <li>
              You are aware that your Personal Information, Aggregated Data,
              Transactional Data, User Account Data, App Data and any additional
              information/ data, will continue to be stored and retained by us
              for a reasonable period after termination of your account on the
              Platform, if necessary for our own legitimate business interests,
              such as fraud prevention, enhancing User safety and security and/
              or to fulfil our legal and contractual obligations and compliance
              requirements. Additionally, Pemant may continue to retain your
              data in anonymised form for analytical and research purposes.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">DATA LOCALISATION</h1>
          <ol>
            <li>
              Pemant maintain your information/ data on servers (
              <a href="https://pemant.credochain.com">
                https://pemant.credochain.com
              </a>
              ) located in India.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">LINKS TO OTHER SITES</h1>
          <ol>
            <li>
              Our Platform may contain links to other websites that may also
              collect your information/ data. We are not responsible for the
              privacy practices or the content of those linked websites.
            </li>
            <li>
              This Privacy Policy only addresses the access, collection,
              storage, use, sharing and/ or disclosure of information/ data
              collected by us.
            </li>
            <li>
              If you visit any websites through the links on the Platform,
              please ensure that you go through the privacy policies of each of
              those websites. You agree and acknowledge that Pemant do not have
              control over any of their policies, nor do we have any liability
              in this regard.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">CHANGE IN PRIVACY POLICY</h1>
          <ol>
            <li>
              Pemant keep our Privacy Policy under regular review and may amend
              this Privacy Policy from time to time, at our sole discretion.
            </li>
            <li>
              Accordingly, the terms of this Privacy Policy may change and if it
              does, these changes will be posted on this page and, where
              appropriate, notified to you by email. The amended Privacy Policy
              may be displayed on-screen and you may be required to read and
              accept the changes to continue your use of the Platform and/ or
              Services.
            </li>
            <li>
              Pemant encourage you to periodically review this page for the
              latest information on our privacy practices. In case, you do not
              agree to an amendment/ change to this Privacy Policy, you have the
              right to discontinue accessing/ using the Platform, removing the
              access permission to and/ or uninstalling the App, and to
              discontinue availing the Services.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">
            ACCESSING/ DELETION OF YOUR INFORMATION AND OPT-OUT
          </h1>
          <ol>
            <li>
              You can, at any point of time, choose to edit/ modify or delete/
              withdraw any of your Personal Information shared for use of the
              Platform or Services or opt-out of receiving non-essential
              (promotional and/ or marketing-related) communications after
              setting up an account with us by writing at{" "}
              <a href="mailto:support@credochain.com">support@credochain.com</a>
              . Please note that this shall affect the services provided by us
              and you shall be required to pay the dues or charges payable for
              the services rendered by us prior to date of deletion of your data
              from our servers (
              <a href="https://pemant.credochain.com">
                https://pemant.credochain.com
              </a>
              ).
            </li>
            <li>
              In case of modification of Personal Information, you will be
              required to furnish supporting documents relating to change in
              Personal Information for the purpose of verification by the
              Company.
            </li>
            <li>
              Upon receipt of such a request, Pemant shall delete your
              information from our servers (
              <a href="https://pemant.credochain.com">
                https://pemant.credochain.com
              </a>
              ) within a period of 7 (seven) business days from receipt of such
              request for deletion. We shall also, within the said time period,
              inform our lending partners, affiliates and other third-party
              service providers associated with us to delete any of your
              information/ data that we may have shared with them. However, we
              shall not be liable for any delay in deletion of, or failure to
              delete, your information/ data by any such lending partners and
              other third-party service providers. Please note that deleting or
              withdrawing information may affect the Services we provide to you.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">
            NON-SOLICITATION OF MINOR INFORMATION
          </h1>
          <ol>
            <li>
              Pemant do not intentionally solicit, access, collect, use, store
              or share Personal Information from/ of persons below the age of 18
              (eighteen) years.
            </li>
            <li>
              Our Platform is only meant for use by persons who are eligible to
              form a legally binding contract under the Indian Contract Act,
              1872. In case you are less than 18 (eighteen) years of age, Pemant
              request you to use the Platform and/ or avail the Services only
              under the supervision of your parent, legal guardian, or other
              adult competent in this regard under Applicable Law.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">DISCLAIMER</h1>
          <ol>
            <li>
              Pemant do not have any control over any information/ data that you
              may choose to make publicly available. For example, if you post
              reviews, comments, or messages on public sections of the Platform
              or on an application store (such as the App Store or Play Store),
              you do so at your own risk. We are not liable for third party
              misuse of such information/ data.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-4">GRIEVANCE REDRESSAL MECHANISM</h1>
          <ol>
            <li>
              When Pemant receive complaints, we respond by contacting the
              person who made the complaint. Additionally, for any complaints
              that we cannot resolve with you directly, we work with the
              appropriate regulatory authorities, including local data
              protection authorities, to resolve any such complaints.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-8">DATA SECURITY</h1>
          <ol className="list-decimal ml-8">
            <li>
              In accordance with Information Technology Act 2000 and rules made
              there under, the name and contact details of the Grievance Officer
              are provided below for your reference:
            </li>
          </ol>
          <div className="ml-8 mt-4 mb-4">
            <p>
              <strong>Name:</strong> Vaibhav Anand
            </p>
            <p>
              <strong>Email:</strong>{" "}
              <a
                href="mailto:vaibhavanand@credochain.com"
                className="text-blue-50"
              >
                vaibhavanand@credochain.com
              </a>
            </p>
            <p>
              <strong>Address:</strong> 27A, 3rd Floor Hauz Khas Village, New
              Delhi - 110016
            </p>
          </div>
          <h1 className="font-bold mt-8 mb-8">
            GOVERNING LAW AND JURISDICTION
          </h1>
          <ol className="list-decimal ml-8">
            <li>
              The relationship between you and Credochain shall be governed by
              the laws of India and all claims or disputes arising therefrom
              shall be subject to the exclusive jurisdiction of the courts of
              New Delhi.
            </li>
          </ol>
          <h1 className="font-bold mt-8 mb-8">
            CREDOCHAIN CONTACT INFORMATION
          </h1>
          <div className="ml-8 mt-4 mb-4">
            <p>
              <strong>Website:</strong>{" "}
              <a href="https://pemant.in" className="text-blue-50">
              https://pemant.in   
              </a>
            </p>
            <p>
              <strong>Support:</strong>{" "}
              <a href="mailto:support@credochain.com" className="text-blue-50">
                support@credochain.com
              </a>
            </p>
            <p>
              <strong>Phone:</strong> +91 11-41716192
            </p>
            <p>
              <strong>Office:</strong> Plot 27A, III Floor, Hauz Khas Village,
              New Delhi – 110016
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
