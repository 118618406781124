import React, { useState, ChangeEvent, FormEvent } from "react";
import { API_ACCOUNT_DELETE_REQUEST } from "src/utils/APIUrls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Navbar } from "src/components";

const DeleteAccount: React.FC = () => {
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const navigate = useNavigate();

  const deleteAccountRequest = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const dataToSend = {
      mobileNumber: String(mobileNumber),
    };

    if (mobileNumber?.length === 10) {
      axios
        .post(API_ACCOUNT_DELETE_REQUEST, dataToSend)
        .then(({ data }) => {
          if (data.code === 200) {
            alert("Request Created Successfully. We will keep you updated");
            navigate(`/`);
            setMobileNumber("");
          }
        })
        .catch((err) => {
          alert("Something went wrong");
          throw err;
        });
    } else {
      alert("Please provide correct mobile number");
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMobileNumber(e.target.value);
  };

  return (
    <>
      <Container>
        <Navbar theme="dark" />
        <div className="p-4 my-12">
          <div className="w-full md:max-w-2xl mx-auto p-8 border border-grey-50 rounded-2xl">
            <p className="mb-12">
              Please provide your mobile number to initiate deletion request
            </p>
            <div className="p-8 my-4 border border-grey-50 rounded-lg bg-white">
              <form
                className="text-sm"
                style={{ marginTop: "1rem" }}
                onSubmit={deleteAccountRequest}
              >
                <label className="font-bold">Enter the mobile number</label>
                <br />
                <input
                  value={mobileNumber}
                  onChange={handleInputChange}
                  type="number"
                  placeholder=" Please Enter your mobile number."
                  min={0}
                  className="border border-grey-50 rounded p-4 my-2 w-full focus:outline-none focus:ring-1 focus:ring-blue-50"
                  maxLength={10}
                />
                <br />
                <button
                  type="submit"
                  className="font-bold bg-blue-50 text-basic-white py-4 my-8 w-full cursor-pointer rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-blue-50"
                >
                  Create Request
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default DeleteAccount;
