import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import { Container, Navbar } from "src/components";
import MileStone from "./MileStone";

const AboutUs = () => {
  return (
    <>
      <div className="bg-gradient-to-r from-[#3882E0] from-22.47% to-[#003CA8] to-71.92% relative min-h-[95vh]">
        <Container>
          <Navbar />
          <div className="grid grid-cols-1 md:grid-cols-1 mt-12 md:mt-32">
            <div className="space-y-5 md:space-y-10">
              <div className="text-3xl md:text-4xl text-basic-white font-semibold">
                Credochain Technologies operate 2 platforms since 2019.
              </div>
              <div className="text-basic-white mt-0 md:mt-3 text-lg md:text-xl">
                <ul>
                  <li className="mt-5">
                    1. Credochain MSME Credit Enablement and Underwriting
                    Analytics Platform.
                  </li>
                  <li className="mt-3">
                    2. Pemant - Deep-Tier Supply Chain Financing Platform
                    targeting Tier-2/3 offline value chains.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
        <div className="w-full h-auto md:h-[220px] absolute bottom-0 z-0">
          <img
            src={FAQBg}
            alt="Full Screen"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <MileStone />
    </>
  );
};

export default AboutUs;
