import React from "react";
import { Container, Navbar } from "src/components";

const TermsAndCondition = () => {
  return (
    <>
      <Container>
        <Navbar theme="dark" />
      </Container>
      <div className="bg-[#EBF3FD]">
        <Container>
          <h1 className="font-bold mt-8 mb-4">Terms of Service</h1>
          <p>
            • These terms and conditions (“Terms”) govern the use of or access
            to{" "}
            <a href="https://pemant.in" className="text-blue-50">
            https://pemant.in
            </a>{" "}
            (“Website”), the “Pemant” mobile application (“App”), and the
            Services (defined below). The Website and App are collectively
            referred to as the “Platform”.
          </p>
          <p>
            • These Terms also include any guidelines, additional terms,
            policies, or disclaimers made available or issued by us from time to
            time. These Terms may be updated by us, without notice to the User
            and the Terms, as amended, shall apply to User from time to time.
            User can always review the most current version of these Terms on
            the Platform. Any term not defined shall have the meaning ascribed
            to it in the Privacy Policy provided on the Website.
          </p>
          <p>
            • These Terms constitute a legally binding contract, enforceable by
            the relevant statutes of law as may be applicable between Credochain
            Technologies Private Limited (“Credochain”) and a user which shall
            include without limitation, a Buyer (defined below), a Supplier
            (defined below) or any user of the Services. Buyers, Suppliers and
            any other user of the Platform and Services shall collectively be
            referred as “you” or "User”.
          </p>
          <p>• User represent and warrant that User:</p>
          <p>• i. is eighteen years of age or older, and</p>
          <p>• ii. Is an Indian resident.</p>
          <p>
            • If User represents an entity, organisation, or any other legal
            person, User confirms and represents that User has the necessary
            power and authority to bind such entity, organisation, or legal
            person to these Terms.
          </p>
          <p>
            • By using the Services, User agrees that User has read, understood,
            and are bound by these Terms, and that User shall comply with the
            requirements listed herein.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article I: SERVICES AND PRODUCT DETAILS
          </h1>
          <p>
            • The Platform allows small businesses such as traders, Retailers,
            manufacturers, , wholesalers and suppliers (hereafter, “Buyers” or
            “Borrower”) to access a working capital loan Through the Platform.
          </p>
          <p>
            • Credochain conducts their own credit assessment of User and
            provides a line of credit.
          </p>
          <p>
            • the Platform uses technology to facilitate transactions between
            Buyers, Suppliers and Customers and uses data-backed insights to
            match payments and receivables to extend, or modify or terminate
            credit provided Through the Platform.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article II: REPAYMENT TO CREDOCHAIN AND INTEREST TO BE CHARGED
          </h1>
          <p>
            • The repayment obligations and the cycle of repayment shall be as
            per the agreement with the lending partners.
          </p>
          <p>
            • The tenure of the loan or the credit facility disbursed shall be
            as per the loan agreement between the Borrower and the lending
            partner.
          </p>
          <p>
            • Further, in case of breach of the loan agreement or the Term or
            any other arrangement by the Borrower with the lending partner or
            Credochain, Credochain or the lending partner shall have the right
            to suspend the use of Platform or services. The suspension maybe
            temporary or permanent depending upon the time required to cure the
            discrepancy.
          </p>

          <h1 className="font-bold mt-8 mb-4">Article III: PROFILE CREATION</h1>
          <p>
            • To avail the Services, a User would be required to create a User
            profile on the Platform (“Profile”). In addition to setting up
            username and password to create the Profile, the User will be
            required to furnish certain details, including but not limited to
            phone numbers and details of their businesses. The User warrants
            that all information furnished in connection with its Profile is and
            shall remain accurate and true in all respect, and agrees that it
            shall promptly update its details on the Platform in the event of
            any change or modification.
          </p>
          <p>
            • The User is solely responsible for maintaining the security and
            confidentiality of its username and password, and agrees to
            immediately notify Credochain of any disclosure or unauthorized use
            of its Profile or any other breach of security with respect to User
            Profile.
          </p>
          <p>
            • The User expressly agrees to be liable and accountable for all
            activities that take place through their Profile. Credochain shall
            in no manner be held liable for any unauthorized access to a User’s
            Profile.
          </p>
          <p>
            • The User agrees to receive communications from Credochain
            regarding:
          </p>
          <p>
            i. information relating to transactions recorded on the Platform;
          </p>
          <p>ii. requests for payment;</p>
          <p>iii. information about Credochain and the Service</p>
          <p>
            iv. promotional offers and services from Credochain and its third
            party partners, and
          </p>
          <p>v. any other matter in relation to the Services.</p>

          <h1 className="font-bold mt-8 mb-4">Article IV: KYC</h1>
          <p>
            • Credochain may be required to undertake certain “know your
            customer” (KYC) processes in relation to Users who intend to avail
            Platform Services. In this regard, Credochain from time to time
            shall require Users to upload information and documents that may be
            necessary to ascertain their eligibility to use certain features of
            the Services (“KYC Documents”).
          </p>
          <p>
            • The User authorizes Credochain, and any third-party service
            provider it may engage with, to process KYC Documents and ascertain
            their eligibility. Any processing undertaken by Credochain shall be
            in accordance with its Privacy Policy of Credochain. The User agrees
            that it may be subject to additional terms of service in the event a
            third-party processes information under this Clause. Further, in the
            event any additional information, data, or documentation being
            required to determine eligibility (collectively, “Additional
            Information”), the User hereby agrees to share such Additional
            Information promptly upon request, and further, authorizes
            Credochain to process such Additional Information.
          </p>
          <p>
            • The User agrees and warrants to provide true, complete, and
            up-to-date KYC Documents and Additional Information. The User
            further acknowledges that:
          </p>
          <p>
            i. any false or misleading information provided in this regard shall
            constitute a material breach of these Terms; and
          </p>
          <p>
            ii. access to certain features of the Services may be limited or
            denied if the User fails to share KYC Documents and Additional
            Information.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article V: TRANSACTION INFORMATION:
          </h1>
          <p>
            • Users may upload information relating to transactions performed
            with each other and with Customers on Platform, including the sale
            of goods, costs, amounts paid and payable, and details of goods and
            services, on the Platform (such information is referred to as
            “Transaction Information”).
          </p>
          <p>
            • Transaction Information may be exchanged between User and its
            Customer through telephonic calls, text message, WhatsApp, email, or
            other electronic mediums that would depend on the contact details of
            User and its Customers provided on the Platform.
          </p>
          <p>
            • At the time of creating or uploading the first Transaction
            Information with respect to a Customer, the Buyer shall inform such
            Customer of its use of the Platform to record such Transaction
            Information and Transaction Information related to future
            transactions and seek such Customer’s express consent in this regard
            and to:
          </p>
          <p>
            i. information relating to their transactions recorded on the
            Platform;
          </p>
          <p>ii. requests for payment;</p>
          <p>iii. information about Credochain and the Services; and</p>
          <p>iv. any other matter in relation to the Platform.</p>

          <h1 className="font-bold mt-8 mb-4">
            Article VI: THIRD PARTY SERVICES:
          </h1>
          <p>
            • The Services may include services, content, documents, and
            information owned by, licensed to, or otherwise made available by a
            third party (“Third Party Services”) or contain links to Third Party
            Services. Users understand that Third Party Services are the
            responsibility of the third party that created or provided it and
            acknowledge that use of such Third-Party Services is solely at their
            own risk.
          </p>
          <p>
            • Credochain makes no representations and excludes all warranties
            and liabilities arising out of or pertaining to such Third-Party
            Services, including their accuracy or completeness. Further, all
            intellectual property rights in and to Third Party Services are the
            property of the respective third parties.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article VII: USER RESPONSIBILITIES:
          </h1>
          <p>
            • The User represents and warrants that all information that is
            provided through or in relation to the Services is complete, true,
            and correct on the date of agreeing to these Terms and shall
            continue to be complete, true, and correct while the User avails the
            Services. Should any information that the User provides change
            during the existence of these Terms, the User undertakes to
            immediately bring such change to Credochain’s notice.
          </p>
          <p>
            • User consents to the sharing of the information shared with the
            Platform with the other third-party service provider to the extent
            essential to provide Service.
          </p>
          <p>
            • User shall not share its/his/her account and login information,
            one time password with any third party, let anyone else access or
            use the account, or do anything else that might jeopardize the
            security of the Account;
          </p>
          <p>
            • In the event User becomes aware of or reasonably suspect any
            breach of security, including without limitation any loss, theft, or
            unauthorized disclosure of the login information, User shall
            immediately notify Credochain at{" "}
            <a href="mailto:support@credochain.com" className="text-blue-50">
              support@credochain.com
            </a>{" "}
            and modify User login information;
          </p>
          <p>
            • User is solely responsible for maintaining the confidentiality of
            its/his/her login information (including password), and for
            restricting access to hardware device while logged into the Platform
          </p>
          <p>
            • User shall be responsible for all activities coming from account
            held by the User or from hardware device of the User, including
            purchases, whether or not authorized by User.
          </p>
          <p>
            • Credochain does not accept any responsibility or liability for any
            loss or damage the User may suffer or incur if any information,
            documentation, material, or data provided to avail the Services is
            incorrect, incomplete, inaccurate, or misleading, or if the User
            fails to disclose any material fact.
          </p>
          <p>
            • The User shall be solely responsible for ensuring compliance with
            applicable laws, and shall be solely liable for any liability that
            may arise due to a breach of its obligations in this regard.
          </p>
          <p>
            • The User shall extend all cooperation to Credochain in its defence
            of any proceedings that may be initiated against it due to a breach
            of the User’s obligations or covenants under these Terms.
          </p>
          <p>
            • The User shall not use the Services in any manner except as
            expressly permitted in these Terms. Without limiting the generality
            of the preceding sentence, the User may not:
          </p>
          <p>
            i. infringe any proprietary rights, including but not limited to
            copyrights, patents, trademarks, or trade secrets of any party;
          </p>
          <p>
            ii. except as may be provided hereunder, copy, display, distribute,
            modify, publish, reproduce, store, transmit, post, translate, create
            any derivative works from, or license the Services;
          </p>
          <p>
            iii. use the Services to transmit any data or send or upload any
            material that contains viruses, Trojan horses, worms, time-bombs,
            keystroke loggers, spyware, adware, or any other harmful programmes
            or similar computer code designed to adversely affect the operation
            of any computer software or hardware;
          </p>
          <p>
            iv. use any robot, spider, other automated device, or manual process
            to monitor or copy or translate, reverse engineer, transfer, rent,
            resell, lease, license, assign, decipher, decompile, disassemble,
            distribute, or otherwise attempt to derive any source code
            underlying ideas or algorithms of any part of the Platform; and
            alter, modify, translate, or otherwise create derivative works of
            any part of the Platform or Services or any portion thereof;
          </p>
          <p>
            v. engage in the systematic retrieval of content from the Platform
            or Services to create or compile, directly or indirectly, a
            collection, compilation, database or directory;
          </p>
          <p>vi. use the Services in-</p>
          <p>(A) any unlawful manner,</p>
          <p>(B) for fraudulent or malicious activities, or</p>
          <p>(C) in any manner inconsistent with these Terms; or</p>
          <p>(D) violate applicable laws in any manner.</p>

          <h1 className="font-bold mt-8 mb-4">
            Article VIII: OUR INTELLECTUAL PROPERTY:
          </h1>
          <p>
            • All rights, title, and interest in and to the Platform and
            Services, including all intellectual property rights arising out of
            the Platform and Services, are owned by or otherwise licensed to
            Credochain. Subject to compliance with these Terms, Credochain
            grants the User a non-exclusive, non-transferable, non-sub
            licensable, royalty-free, revocable, and limited licence to use the
            Platform and Services in accordance with these Terms and its written
            instructions issued from time to time.
          </p>
          <p>
            • Credochain may request Users to submit suggestions and other
            feedback, including bug reports, relating to the Platform or
            Services from time to time (“Feedback”).
          </p>
          <p>
            • Credochain may freely use, copy, disclose, publish, display,
            distribute, and exploit the Feedback without any payment of royalty,
            acknowledgement, prior consent, or any other form of restriction
            arising out of the User’s intellectual property rights.
          </p>
          <p>
            • Except as stated in these Terms, nothing in these Terms should be
            construed as conferring any right in or license to Credochain’s or
            any third party’s intellectual rights.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article IX: TERM AND TERMINATION:
          </h1>
          <p>
            • These Terms shall remain in effect unless terminated in accordance
            with the terms hereunder
          </p>
          <p>
            • Credochain may terminate a User’s access to or use of the Services
            of the Platform, or any portion thereof, immediately and at any
            point, at its sole discretion if the User violates or breaches any
            of its obligations, responsibilities, or covenants under these Terms
            or terms or conditions of the agreement entered into with Credochain
            or the lending partner.
          </p>
          <p>• Upon termination under Article 10(2):</p>
          <p>i. the Services will “time-out”;</p>
          <p>
            ii. the User shall not be eligible to avail any features of the
            Platform; and
          </p>
          <p>
            iii. these Terms shall terminate, except for those Articles and
            Clauses that expressly or are intended to survive termination or
            expiry.
          </p>
          <p>
            • Notwithstanding anything to the contrary contained in the Terms,
            upon termination of a User’s access to or use of the Services and
            the Platform, all amounts or outstanding monies due by said User in
            relation to their use of or access to the Services shall become
            immediately payable.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article X: DISCLAIMERS AND WARRANTIES
          </h1>
          <p>• The use of the Services is at User’s sole risk.</p>
          <p>
            • User acknowledges and agrees that Credochain is not engaged in the
            provision, grant, or disbursement of any financial product.
            Credochain is not and will not be responsible for any claim or for
            any damages suffered that are related, directly or indirectly, to or
            arise out of the use of financial products.
          </p>
          <p>
            • Credochain is merely a technology platform service provider and:
          </p>
          <p>i. is not registered with the Reserve Bank of India;</p>
          <p>
            ii. does not hold any licence to engage in any activities relating
            to financial products; and
          </p>
          <p>
            iii. is not a financial institution under the Companies Act, 2013 or
            the Banking Regulation Act, 1949 or any other laws for the time
            being in force in India.
          </p>
          <p>
            • To the extent permitted by applicable law, the Services are
            provided on an “as is” and “as available” basis. Credochain does not
            warrant that operation of the Services will be uninterrupted or
            error free or that the functions contained in the Services will meet
            User’s requirements.
          </p>
          <p>
            • To the fullest extent permissible under applicable law, Credochain
            expressly disclaims all warranties of any kind, express or implied,
            arising out of the Services, including warranties of Useability,
            fitness for a particular purpose, satisfactory quality, accuracy,
            title and non-infringement, compatibility, applicability, usability,
            appropriateness, and any warranty that may arise out of course of
            performance, course of dealing, or usage of trade.
          </p>
          <p>
            • User hereby accepts full responsibility for any consequences that
            may arise from User’s use of the Services, and expressly agree and
            acknowledge that Credochain shall have absolutely no liability with
            respect to the same.
          </p>
          <p>
            • To the fullest extent permissible by law, Credochain, its
            affiliates, and its related parties each disclaim all liability to
            User for any loss or damage arising out of or due to:
          </p>
          <p>
            i. User’s use of, inability to use, or availability or
            unavailability of the Services, including any Third-Party Services;
          </p>
          <p>
            ii. the occurrence or existence of any defect, interruption, or
            delays in the operation or transmission of information to, from, or
            through the Services, communications failure, theft, destruction or
            unauthorized access to Credochain’s records, programmes, services,
            server (
            <a href="https://pemant.credochain.com" className="text-blue-50">
              https://pemant.credochain.com
            </a>
            ), or other infrastructure relating to the Services; or
          </p>
          <p>
            iii. the failure of the Services to remain operational for any
            period of time.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            Article XI: CONSENT TO USE DATA
          </h1>
          <p>
            • User have been made aware that Credochain may use information and
            data pertaining to him/her use of the Services for analytics, trend
            identification, and purposes of statistics to further enhance the
            effectiveness and efficiency of the Platform and other purposes as
            mentioned in the Privacy Policy of Credochain.
          </p>
          <p>
            • Credochain maintains data security subject to applicable laws of
            India. However, please note that Credochain may be directed by law
            enforcement agencies or the government and related bodies to
            disclose data in relation to Users and/ or Customers in connection
            with criminal proceedings. User understands and agrees that in such
            instances, Credochain shall have the right to share such data with
            relevant agencies or bodies.
          </p>

          <h1 className="font-bold mt-8 mb-4">
            ARTICLE XII: LIMITATION OF LIABILITY
          </h1>
          <p>
            • Credochain (including its officers, directors, employees,
            representatives, affiliates, and providers) will not be responsible
            or liable for (a) any injury, death, loss, claim, act of god,
            accident, delay, or any direct, special, exemplary, punitive,
            indirect, incidental or consequential damages of any kind (including
            without limitation lost profits or lost savings), whether based in
            contract, tort, strict liability or otherwise, that arise out of or
            is in any way connected with (i) any failure or delay (including
            without limitation the use of or inability to use any component of
            the Platform), or (ii) any use of the Platform, or (iii) the
            performance or non-performance by Credochain or any provider or (b)
            any damages to or viruses that may infect User’s computer equipment
            or other property as the result of User’s access to the website or
            downloading of any content from the website or while downloading
            Platform.
          </p>
          <p>
            • While we strive to be accurate in our assessment of eligible
            credit limit, User must acknowledge that the final decisions
            regarding the loan/credit being provided to the User is taken by our
            lending partner. User agrees to not hold Credochain liable if any
            lending partner does not make credit available to the User.
          </p>

          <h1 className="font-bold mt-8 mb-4">ARTICLE XIII ASSIGNMENT</h1>
          <p>
            • User will not assign or otherwise transfer its rights or
            obligations under these Terms.
          </p>

          <h1 className="font-bold mt-8 mb-4">ARTICLE XIV INDEMNITY</h1>
          <p>
            • User shall, indemnify and hold Credochain harmless from all
            third-party claims and settlements agreed to by User and all costs
            and direct damages awarded to a third party to the extent they arise
            out of User’s infringement of any third-party rights, or breach of
            any provision of this Agreement, or making representations or
            warranties regarding the Service to other third parties.
          </p>
          <p>
            • Customer hereby agrees to indemnify and hold harmless Credochain
            against any damages, losses, liabilities, settlements and expenses
            (including without limitation costs and attorneys' fees) in
            connection with any claim or action that arises from an alleged
            violation of this Agreement or otherwise from User’s use of the
            Services.
          </p>
          <p>
            • Nothing in this Clause shall limit the User’s obligations and
            liability in cases of loss of life, bodily injury, damage to a
            person’s health or in cases of intentional infliction of harm,
            deceit, fraud or gross negligence suffered by Credochain due to acts
            and omissions employed, used or performed by its obligations.
          </p>

          <h1 className="font-bold mt-8 mb-4">ARTICLE XV GOVERNING LAW</h1>
          <p>
            • The applicable laws of India, without regard to its conflict of
            laws rules, shall govern these Terms. In case of any legal action
            courts located in New Delhi, India shall have jurisdiction over such
            matters.
          </p>
        </Container>
      </div>
    </>
  );
};

export default TermsAndCondition;
