import { MdEmail } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { Container } from "src/components/atoms";
import LogoHeader from "src/assets/icons/logo-black.svg";
import MainDown from "src/assets/images/home/credit-graph.gif";
import MainUp from "src/assets/images/home/sales-graph.gif";
import plsLink from "src/assets/images/home/googlePlaystore.png";
import QRcode from "src/assets/images/home/QrCode.png";
import { FaFacebook, FaLinkedin } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const onLinkClick = (link: string) => {
    navigate(link);
  };

  return (
    <div className="border-t-2 border-[#D9D9D9] text-sm md:text-md">
      <Container>
        <div className="flex flex-col md:flex-row justify-between gap-y-5 md:gap-y-10">
          <div className="text-basic-black space-y-4">
            <img
              src={LogoHeader}
              alt="Pemant"
              className="h-[24px] md:h-[30px]"
            />
            <div className="flex text-sm font-bold items-center space-x-12">
              <div className="flex items-center">
                <span className="mt-5">Sales</span>
                <img src={MainUp} className="h-[50px] ml-1" />
              </div>
              <div className="flex items-center">
                <span className="mt-5">Credit</span>
                <img src={MainDown} className="h-[50px] ml-1" />
              </div>
            </div>
            <div className="flex space-x-5">
              <a href="https://www.facebook.com/credochain/">
                <FaFacebook className="cursor-pointer" size={32} color="#1877F2" />
              </a>
              <a href="https://www.linkedin.com/company/credochain-technologies-pvt-ltd/">
                <FaLinkedin className="cursor-pointer" size={32} color="#0A66C2" />
              </a>
              <a href="https://www.youtube.com/channel/UCp2Q0cAK2idsocp8xTtjMSA">
                <FaYoutube className="cursor-pointer" size={32} color="#FF0000" />
              </a>
            </div>
            <div className="flex items-center">
              <a
                className="pls_link_wrapper"
                href="https://play.google.com/store/apps/details?id=com.pemantapp"
              >
                <img className="h-16" src={plsLink} alt="Playstore link" />
              </a>
              <p> or </p>
              <img className="h-16 ml-2" src={QRcode} alt="QR Code" />
            </div>
          </div>
          <div>
            <div className="text-xl md:text-4xl font-semibold">Contact Us</div>
            <div className="text-[#80868A] flex items-center gap-x-2 md:gap-x-4 mt-4">
              <MdEmail color="#000" size={22} />
              support@credochain.com
            </div>
            <div className="text-[#80868A] flex items-center gap-x-2 md:gap-x-4 mt-2">
              <MdLocalPhone color="#000" size={22} />
              +91 11-41716192
            </div>
          </div>
          <div>
            <div className="text-xl md:text-4xl font-semibold">Address</div>
            <div className="text-[#80868A] mt-4">
              Plot 27-A, 3rd Floor, <br />
              Hauz Khas Village, <br />
              New Delhi-110016
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-end text-[#80868A] mt-4 md:mt-16">
          <div
            className="cursor-pointer md:border-r-2 border-grey-50 pr-8 mb-4 md:mb-0"
            onClick={() => onLinkClick("/privacy-policy")}
          >
            Privacy Policy
          </div>
          <div
            className="cursor-pointer md:pl-8"
            onClick={() => onLinkClick("/terms-and-conditions")}
          >
            Terms and Conditions
          </div>
        </div>

        <div className="text-[#80868A] md:text-right mt-4 mb-6">
          2024 All rights reserved.
        </div>
      </Container>
    </div>
  );
};

export default Footer;
