import React from "react";

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};
const Container = ({ children, className = "" }: ContainerProps) => {
  return (
    <div
      className={`px-3 md:px-0 py-4 md:py-10 w-full md:w-[75vw] lg:max-w-[80vw] mx-auto ${className}`}
    >
      {children}
    </div>
  );
};

export default Container;
