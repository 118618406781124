import React from "react";

interface SelectProps {
  options: Array<{ value: string; label: string }>;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  name?: string;
  required?:boolean
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  name,
  required
}) => {
  return (
    <div className="relative inline-block w-full md:w-[300px] xl:w-[400px] mt-1">
      <select
        className="block appearance-none w-full bg-white border border-grey-50 hover:border-grey-50 px-4 py-2 pr-8 rounded-md shadow leading-tight focus:outline-none focus:shadow-outline"
        value={value}
        onChange={onChange}
        name={name}
        required={required}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-50">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M7 10l5 5 5-5H7z" />
        </svg>
      </div>
    </div>
  );
};

export default Select;
