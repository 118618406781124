import { useRoutes, type RouteObject } from "react-router-dom";
import { Layout } from "./components";
import Home from "./pages/home";
import SolutionType from "./pages/SolutionType";
import CompanyDetails from "./pages/CompanyDetails";
import FinancialInstitutions from "./pages/solutions/FinancialInstitutions";
import BaseLayout from "./pages/solutions/BaseLayout";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import Blogs from "./pages/Blogs";
import BlogsLayout from "./pages/Blogs/Blogs.layout";
import DeleteAccount from "./pages/DeleteAccount";
import BuyerVerification from "./pages/BuyerVerification/BuyerVerification";
import VerifiedBuyer from "./pages/BuyerVerification/VerifiedBuyer";
import { useScrollToTop } from "./hooks";
import OurTeam from "./pages/OurTeam";

export default function App() {
  useScrollToTop();
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: [{ index: true, element: <Home /> }],
    },
    {
      path: "/solution-type",
      children: [{ index: true, element: <SolutionType /> }],
    },
    {
      path: "/company-details/:userType/:programType",
      children: [{ index: true, element: <CompanyDetails /> }],
    },
    {
      path: "/solutions/:pageName",
      element: <Layout />,
      children: [{ index: true, element: <BaseLayout /> }],
    },
    {
      path: "/solutions/financial-institutions",
      element: <Layout />,
      children: [{ index: true, element: <FinancialInstitutions /> }],
    },
    {
      path: "/about-us",
      element: <Layout />,
      children: [{ index: true, element: <AboutUs /> }],
    },
    {
      path: "/our-team",
      element: <Layout />,
      children: [{ index: true, element: <OurTeam /> }],
    },
    {
      path: "/privacy-policy",
      element: <Layout />,
      children: [{ index: true, element: <PrivacyPolicy /> }],
    },
    {
      path: "/terms-and-conditions",
      element: <Layout />,
      children: [{ index: true, element: <TermsAndCondition /> }],
    },
    {
      path: "/blogs",
      element: <Layout />,
      children: [{ index: true, element: <Blogs /> }],
    },
    {
      path: "/blogs/:blogId",
      element: <Layout />,
      children: [{ index: true, element: <BlogsLayout /> }],
    },
    {
      path: "/delete-account",
      element: <Layout />,
      children: [{ index: true, element: <DeleteAccount /> }],
    },
    {
      path: "/buyer/invitation/:environment/:supplierId/:invitationId/:buyerMobileNumber",
      element: <Layout />,
      children: [{ index: true, element: <BuyerVerification /> }],
    },
    {
      path: "buyer/invitation/verified",
      element: <Layout />,
      children: [{ index: true, element: <VerifiedBuyer /> }],
    },
  ];

  const element = useRoutes(routes);

  return <div>{element}</div>;
}
