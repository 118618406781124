import { Container, Navbar } from "src/components";
import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import { useNavigate, useParams } from "react-router-dom";
import * as config from "./config";
import { useMemo } from "react";

const BaseLayout = () => {
  const { pageName } = useParams<{ pageName: string }>();
  const navigate = useNavigate();
  const objectToMap = useMemo(() => {
    return config.solutionsPage[pageName as keyof typeof config.solutionsPage];
  }, [pageName]);

  const onGetStarted = () => {
    navigate("/solution-type");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-[#3882E0] from-22.47% to-[#003CA8] to-71.92% relative min-h-[95vh]">
        <Container>
          <Navbar />
          <div className="text-basic-white text-4xl md:text-6xl mt-12 md:mt-32">
            {objectToMap.main.heading}
          </div>
          <div className="text-basic-white text-2xl md:text-4xl mt-6 md:mt-10 mb-6 md:mb-0">
            {objectToMap.main.subHeading}
          </div>
          <div
            onClick={onGetStarted}
            className="absolute bottom-4 md:bottom-10 bg-basic-white w-64 py-3 text-center rounded-md cursor-pointer text-blue-50 z-20"
          >
            Get Started
          </div>
        </Container>
        <div className="w-full h-auto md:h-[220px] absolute bottom-0 z-0">
          <img
            src={FAQBg}
            alt="Full Screen"
            className="w-full h-full object-cover"
          />
        </div>     
      </div>
      <Container>
        <div className="flex justify-center text-2xl md:text-5xl text-[#1051D3] my-6 md:my-16 ">
          {objectToMap.working.heading}
        </div>
        <div className="flex justify-center">
          <img
            src={objectToMap.working.gif}
            alt={objectToMap.working.heading}
          />
        </div>
      </Container>
      <div className="bg-[#EBF3FDC9]">
        <Container>
          <div className="text-2xl md:text-5xl text-blue-50 my-2 md:my-16 font-bold">
            {objectToMap.winWIn.heading}
          </div>
          <div
            className={`grid ${
              objectToMap.winWIn?.rightCard?.heading
                ? "grid-cols-1 md:grid-cols-2 gap-x-0 md:gap-x-16"
                : "grid-cols-1"
            } gap-y-8 md:gap-y-8 ${
              !objectToMap.winWIn?.rightCard?.heading
                ? "justify-items-center"
                : ""
            }`}
          >
            <div
              className={`rounded-xl shadow-md mt-2 md:mt-0 bg-basic-white ${
                !objectToMap.winWIn?.rightCard?.heading
                  ? "max-w-xl"
                  : "col-span-1"
              }`}
            >
              <div className="text-xl md:text-4xl text-blue-50 font-semibold text-center border-b border-[#002C8C] py-2 ">
                {objectToMap.winWIn.leftCard.heading}
              </div>
              <div className="grid grid-cols-2">
                {objectToMap.winWIn.leftCard.cards.map((el) => (
                  <div className="p-4 md:p-6" key={el.heading}>
                    <div className="flex">
                      <img
                        className="h-10 md:h-20"
                        src={el.image}
                        alt={el.heading}
                      />
                    </div>
                    <div className="text-blue-50 mt-4 font-semibold text-sm md:text-md">
                      {el.heading}
                    </div>
                    <div className="mt-2 md:mt-4 text-sm md:text-md">
                      {el.subHeading}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {objectToMap.winWIn?.rightCard?.heading && (
              <div className="rounded-xl shadow-md col-span-1 bg-basic-white">
                <div className="text-xl md:text-4xl text-blue-50 font-semibold text-center border-b border-[#002C8C] py-2">
                  {objectToMap.winWIn.rightCard.heading}
                </div>
                <div className="grid grid-cols-2">
                  {objectToMap.winWIn.rightCard.cards.map((el) => (
                    <div className="p-3 md:p-6" key={el.heading}>
                      <div className="flex">
                        <img
                          className="h-10 md:h-20"
                          src={el.image}
                          alt={el.heading}
                        />
                      </div>
                      <div className="text-blue-50 mt-4 font-semibold text-sm md:text-md">
                        {el.heading}
                      </div>
                      <div className="mt-4 text-sm md:text-md">
                        {el.subHeading}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
      <div className="bg-gradient-to-b from-[#3882E0] from-0% to-[#00379E] to-100% flex flex-col items-center relative">
        <div className="w-full h-auto z-10">
          <img
            src={FAQBg}
            alt="Full Screen"
            className="w-full object-cover absolute bottom-0 h-[160px]"
          />
        </div>
        <div className="text-basic-white text-3xl md:text-6xl mt-4 md:mt-28 px-4 md:px-0">
          Unlock your cashflows with Pemant
        </div>
        <div className="text-basic-white text-lg md:text-xl mt-2 px-4 md:px-0">
          Have a chat with our team to check how we can help you grow sales and
          reduce credit.
        </div>
        <div
          onClick={onGetStarted}
          className="bg-[#FFFFFF45] rounded-md text-basic-white px-6 md:px-10 py-2 my-6 md:my-10 cursor-pointer z-20"
        >
          Get Started
        </div>
      </div>
    </>
  );
};

export default BaseLayout;
