import { useState } from "react";
import { IoChevronDownSharp } from "react-icons/io5";

type AppAccordionProps = {
  summary: string;
  details: React.ReactNode;
};
export default function AppAccordion({ summary, details }: AppAccordionProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const onParentClick = () => {
    setIsExpanded((preVal) => !preVal);
  };
  return (
    <div
      onClick={onParentClick}
      className="p-2 md:p-4  bg-basic-white rounded-lg cursor-pointer"
    >
      <div className="text-xl md:text-3xl flex justify-between items-center">
        {summary}{" "}
        <IoChevronDownSharp
          className={`min-w-[16px] md:min-w-[20px] ${
            isExpanded
              ? "-rotate-180 transition-all"
              : "rotate-0 transition-all"
          }`}
        />
      </div>
      <div
        className={`transition-all duration-200 ease-in-out overflow-hidden ${
          isExpanded ? "max-h-96" : "max-h-0"
        }`}
      >
        <div className="transistion-all text-sm md:text-md">{details}</div>
      </div>
    </div>
  );
}
