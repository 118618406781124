import { Container } from "src/components";
import MainUp from "src/assets/images/home/sales-graph.gif";
import MainDown from "src/assets/images/home/credit-graph.gif"
import HomePage from "src/assets/images/home/home.svg";
import FAQBg from "src/assets/images/home/FAQ-bg.svg";
import Navbar from "src/components/molecules/Navbar";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  const onLinkClick = () => {
    navigate("/solution-type");
  };
  return (
    <div className="bg-gradient-to-r from-[#3882E0] from-22.47% to-[#003CA8] to-71.92% relative min-h-[95vh]">
      <Container>
        <Navbar />
        <div className="grid grid-cols-1 md:grid-cols-2 mt-12 md:mt-32 py-4 md:py-0">
          <div className="space-y-5 md:space-y-10">
            <div className="text-[40px] md:text-[52px] text-basic-white">
              Unlock your Cashflows
            </div>
            <div className="flex flex-wrap text-basic-white text-[28px] md:text-[36px] font-bold items-center justify-center md:justify-start gap-8 md:gap-14">
              <div className="flex items-center">
                <span className="mt-7">Sales</span>
                <img
                  src={MainUp}
                  className="h-[45px] mt-4 md:h-[100px] md:mt-0 ml-2 md:ml-3"
                  alt="Sales up"
                />
              </div>
              <div className="flex items-center ml-4">
                <span className="mt-7">Credit</span>
                <img
                  src={MainDown}
                  className="h-[45px] mt-4 md:h-[100px] md:mt-0 ml-2 md:ml-3"
                  alt="Credit down"
                />
              </div>
            </div>
            <div
              className="py-3 w-full md:w-[220px] bg-basic-white text-blue-50 text-center rounded-lg text-xl md:text-2xl cursor-pointer z-20 relative"
              onClick={onLinkClick}
            >
              Get Started
            </div>
          </div>
          <div className="hidden md:flex justify-end">
            <img src={HomePage} alt="Get Start" />
          </div>
        </div>
      </Container>
      <div className="w-full h-auto md:h-[220px] absolute bottom-0 z-0">
        <img
          src={FAQBg}
          alt="Full Screen"
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Main;
