import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  API_GET_BUYER_VERIFICATION_DETAILS,
  API_GET_BUYER_VERIFICATION_DETAILS_PROD,
} from "src/utils/APIUrls";
import { useNavigate, useParams } from "react-router-dom";

interface BuyerDetails {
  supplierName?: string;
  supplierGstin?: string;
  buyerName?: string;
  buyerGstin?: string;
  buyerMobileNumber?: string;
  buyerEmailId?: string;
  averageMonthlySales?: string;
  link?: string;
}

const BuyerVerification: React.FC = () => {
  const [buyerDetails, setBuyerDetails] = useState<BuyerDetails | null>(null);
  const navigate = useNavigate();
  const { environment, supplierId, buyerMobileNumber } =
    useParams<Record<string, string>>();

  useEffect(() => {
    const API_USER_DATA =
      environment === "prod"
        ? API_GET_BUYER_VERIFICATION_DETAILS_PROD
        : API_GET_BUYER_VERIFICATION_DETAILS;
    const dataToSend = { buyerMobileNumber, supplierId };

    axios
      .post(API_USER_DATA, dataToSend)
      .then(({ data }) => {
        if (data.code === 200) {
          setBuyerDetails(data.data);
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        throw err;
      });
  }, [environment, supplierId, buyerMobileNumber]);

  const {
    supplierName,
    supplierGstin,
    buyerName,
    buyerGstin,
    buyerMobileNumber: buyerMobile,
    buyerEmailId,
    averageMonthlySales,
    link,
  } = buyerDetails || {};

  const handleConfirmClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (link) {
      fetch(link)
        .then((data) => {
          if (data.status === 200) {
            navigate(`/buyer/invitation/verified`);
          }
        })
        .catch(() => {
          alert("Something went wrong");
        });
    }
  };

  return (
    <div className="p-4">
      <p className="text-center text-lg mb-4 font-semibold">
        Dear User,
        <br />
        Your supplier has invited you to join pemant.
        <br />
        Please Confirm Your Details.
      </p>
      <div className="bg-white p-4 rounded-md shadow-md mb-4">
        <div className="font-bold text-xl mb-2 text-blue-50">
          Supplier Details
          <hr className="border-grey-50 my-2" />
        </div>
        <div className="mb-2">
          <div className="font-semibold">Name</div>
          <div className="text-green-50 font-bold">{supplierName || "-"}</div>
        </div>
        <div>
          <div className="font-semibold">GST Number</div>
          <div>{supplierGstin || "-"}</div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-md shadow-md mb-4 mt-5">
        <div className="font-bold text-xl mb-2 text-blue-50">
          Your Details
          <hr className="border-grey-50 my-2" />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 mb-2">
            <div className="font-semibold">Name</div>
            <div className="text-green-50 font-bold">{buyerName || "-"}</div>
          </div>
          <div>
            <div className="font-semibold">GST Number</div>
            <div className="break-all">{buyerGstin || "-"}</div>
          </div>
          <div>
            <div className="font-semibold">Mobile Number</div>
            <div className="break-all">{buyerMobile || "-"}</div>
          </div>
          <div>
            <div className="font-semibold">Email</div>
            <div className="break-all">{buyerEmailId || "-"}</div>
          </div>
          <div>
            <div className="font-semibold">Avg. Monthly Sale</div>
            <div className="break-all">₹ {averageMonthlySales || "-"}</div>
          </div>
        </div>
      </div>
      <form>
        <div className="text-center">
          <button
            className="bg-blue-50 mb-5 mt-2 text-basic-white p-2 rounded-md w-3/5 mx-auto border border-blue-50 cursor-pointer font-semibold"
            onClick={handleConfirmClick}
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default BuyerVerification;
