import { Container } from "src/components";
import Iconone from "src/assets/images/solutions/Ugrow.svg";

const LendingPartner = () => {
  return (
    <Container>
      <div className="text-center">
        <h1 className="text-4xl md:text-4xl font-semibold my-4 md:my-4">
          Our Partners
        </h1>
        <p className="text-xl md:text-2xl font-light my-2">
          "Empowering Financial Innovation Together"
        </p>
      </div>
      <div className="text-center mt-7">
        <div className="flex justify-center space-x-24">
          <div className="p-8 border border-grey rounded-[15px] inline-block">
            <img src={Iconone} alt="UGRO Capital" className="h-12" />
          </div>
          <div className="p-8 border border-grey rounded-[15px] inline-block">
            <p className="text-2xl md:text-2xl font-light my-2">
              GANESH LEASFIN
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default LendingPartner;
