import React from "react";

type LabelProps = {
  children: string;
  className?: string;
};

const Label = ({ children, className }: LabelProps) => {
  return <div className={`${className} text-lg md:text-xl`}>{children}</div>;
};

export default Label;
